<style scoped>
.va-container{
    display: flex;
    flex-direction: column;
    padding: 16px;
    max-height: 80vh;
}
.card-body{
  padding-top: 0;
}
.choose-bank{
    display: flex;
    height: 58px;
    padding: 8px 16px;
    border-radius: 8px;
    border: 0.5px solid #0E6027;
    background: #DEFFE7;
    color: #00315D;
    font-family: "Public Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.2px;
    cursor: pointer;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;
}
.close-btn{
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 8px;
    background: linear-gradient(180deg, #FF6D39 0%, #FF5011 100%);
    color: #FFF;
    text-align: center;
    font-family: "Public Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.24px;
    cursor: pointer;
    margin-top: 16px;
}
.close-btn:hover {
    background: linear-gradient(180deg, #FF5011 0%, #D4430E 100%);
}
.close-btn:active {
    background: linear-gradient(180deg, #D4430E 0%, #AA350B 100%);
}
.vaContainer{
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 8px 24px;
  margin-bottom: 12px;
  background: linear-gradient(180deg, #003B70 0%, #00315D 100%);
  color: #C5D9EB;
  font-family: "Noto Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.vanumber{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vanumber span{
  font-weight: 400;
}
.vaCopy{
  display: flex;
  padding: 6px 8px;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 4px;
  border-radius: 8px;
  background: linear-gradient(180deg, #FF6D39 0%, #FF5011 100%);
  color: #FFF;
  text-align: center;
  font-family: "Public Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.24px;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  right: -45px;
  top: -2px;
}
.vaCopy:hover{
  background: linear-gradient(180deg, #FF5011 0%, #D4430E 100%);
}
.vaCopy:active{
  background: linear-gradient(180deg, #D4430E 0%, #AA350B 100%);
}
.vanumberfirst {
  color: #FFF;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; 
  letter-spacing: 0.28px;
  margin-bottom: 5px;
}
.banklist{
    margin: 4px 0;
    display: flex;
    flex-direction: column;
    padding: 8px;
    margin-right: 0;
    align-items: left;
    gap: 4px;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 58, 109, .48);
    position: absolute;
    width: 90%;
    z-index: 999;
    top: 110px;
    justify-content: center;
    cursor: pointer;
}
.selectedbank{
  color: #000;
  font-family: "Public Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; 
  letter-spacing: 0.28px;
}
.bankitem{
  height: 32px;
  display: flex;
  flex-grow: 1;
  border: 0.5px dashed transparent;
  border-bottom: #EBEBEB;
  align-items: center;
  padding: 4px 0;
  align-items: center;
}
.bankitem:hover{
  background: #F4F9FF;
}
.banklogo{
  height: 20px;
  width: 75px;
  text-align: left !important;
}
.logo{
  height: 16px;
}
ol {
    padding-inline-start: 15px;
}
.guidance{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.btn{
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    border-radius: 8px !important;
    border: 1px solid #EBEBEB 8px !important;
    background: #FFF;
    color: #393939 !important;
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700 !important;
    line-height: 22px;
    letter-spacing: 0.32px;
}
.card{
    border-radius: 8px !important;
    border: 1px solid #EBEBEB 8px !important;
    padding: 0 !important;
}
#headingOne, #headingTwo, #heading3, #heading4, #heading5 {
    display: flex;
    justify-content: space-between;
    padding-right: 16px;
}
</style>
<template>
    <div class="va-container">
        <div class="choose-bank" @click="showBankList = !showBankList">
            <div>
                {{ $t('chooseBank') }}
                <div v-if="VAinfo.bank !== ''" class="bankitem">
                  <div class="banklogo">
                    <img
                      class="logo"
                      :src="'images/' + VAinfo.bank + '-logo.svg'"
                      :alt="'Bank' + VAinfo.bank"
                    />
                  </div>
                  <div class="selectedbank">
                    {{VAinfo.bank}}
                  </div>
                </div>
            </div>
            <div><img src="images/accordion.svg"/></div>
        </div>
        <div class="vaContainer">
          {{ $t('vaLabel',{bank: VAinfo.bank}) }}
          <div class="vanumber">
            <div class="vanumberfirst">
              {{VAinfo.accountNumber}}
            </div>
            <div 
              class="vaCopy"
              @click="
                copy(VAinfo.accountNumber);
                makeToast(VAinfo.bank + ' ' + VAinfo.accountNumber);
              "
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <path d="M12.3291 6.00001V4.66668C12.3291 3.9303 11.7316 3.33334 10.9945 3.33334H4.32163C3.58457 3.33334 2.98706 3.9303 2.98706 4.66668V8.66668C2.98706 9.40306 3.58457 10 4.32163 10H5.6562M6.99077 12.6667H13.6636C14.4007 12.6667 14.9982 12.0697 14.9982 11.3333V7.33334C14.9982 6.59696 14.4007 6.00001 13.6636 6.00001H6.99077C6.25371 6.00001 5.6562 6.59696 5.6562 7.33334V11.3333C5.6562 12.0697 6.25371 12.6667 6.99077 12.6667ZM11.6618 9.33334C11.6618 10.0697 11.0643 10.6667 10.3272 10.6667C9.59014 10.6667 8.99263 10.0697 8.99263 9.33334C8.99263 8.59696 9.59014 8.00001 10.3272 8.00001C11.0643 8.00001 11.6618 8.59696 11.6618 9.33334Z" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              {{ $t('copy') }}
            </div>
          </div>
          {{$t('amountToBePaid')}}
          <div class="vanumber vanumberfirst"><span>{{$t('currency')}}</span>{{amount}}</div>
        </div>
        <div class="banklist" v-if="showBankList">
          <div 
            class="bankitem" 
            v-for="(data, i) in Global.VirtualAccount" 
            :key="i"
            @click="showBankList = false, changeBank(data.bank, data.prefix, data.prefix2, data.customize, opportunity)"
          >
            <div class="banklogo">
              <img
                class="logo"
                :src="'images/' + data.bank + '-logo.svg'"
                :alt="'Bank' + data.bank"
              />
            </div>
            {{data.bank}}
          </div>
        </div>
        <div class="row" v-for="(data, i) in virtualaccounts" :key="i">
            <div
              class="accordion col-12 col-sm-12 col-md-12 col-lg-12 guidance"
              :id="'accordion-' + data.bank"
              v-if="VAinfo.bank == data.bank"
            >
              <div class="card">
                <div 
                  id="headingOne"
                  class="btn" 
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                    <div>Mobile Banking {{ data.bank }}</div>
                    <img src="images/accordion.svg"/>
                </div>

                <div
                  id="collapseOne"
                  class="collapse"
                  aria-labelledby="headingOne"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva1'" v-if="$t('mbanking_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva2'" v-if="$t('mbanking_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva3'" v-if="$t('mbanking_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva4'" v-if="$t('mbanking_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('mbanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('mbanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("mbanking_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva5'" v-if="$t('mbanking_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva6'" v-if="$t('mbanking_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva7'" v-if="$t('mbanking_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('mbanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'mbanking_' + data.bank + '.guideva8'" v-if="$t('mbanking_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('mbanking_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('mbanking_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("mbanking_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("mbanking_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>

              <div class="card">
                <div id="headingTwo"
                  class="btn text-left collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div>Internet Banking {{ data.bank }}</div>
                  <img src="images/accordion.svg"/>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva1'" v-if="$t('internetBanking_' + data.bank + '.guideva1') !== ''">
                        <a v-if="$t('internetBanking_' + data.bank + '.link') !== ''" :href="
                            data.bank == 'MANDIRI'
                              ? 'https://ibank.bankmandiri.co.id/'
                              : data.bank == 'BNI'
                              ? 'https://ibank.bni.co.id/' 
                              : data.bank == 'PERMATA' 
                              ? 'https://www.permatanet.com/' 
                              : data.bank == 'BRI'
                              ? 'https://ib.bri.co.id/ib-bri/'
                              : 'https://ibank.klikbca.com/'
                          "
                          target="_blank"
                          style="color: #fe632b">{{
                            data.bank == "MANDIRI"
                              ? "https://ibank.bankmandiri.co.id/"
                              : data.bank == "BNI"
                              ? "https://ibank.bni.co.id/"
                              : data.bank == 'PERMATA' 
                              ? 'https://www.permatanet.com/' 
                              : data.bank == 'BRI'
                              ? 'https://ib.bri.co.id/ib-bri/'
                              : "https://ibank.klikbca.com/"
                          }}</a>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva2'" v-if="$t('internetBanking_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva3'" v-if="$t('internetBanking_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva4'" v-if="$t('internetBanking_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva5'" v-if="$t('internetBanking_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva5') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva5') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva5") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva5') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva5') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva5") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva5') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide5') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva5') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva5") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva5') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva5") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva6'" v-if="$t('internetBanking_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.0.guideva6') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva6') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva6") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.1.guideva6') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva6') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva6") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva6') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.2.boldguide6') == 'y'">
                            <b v-if="$t('internetBanking_' + data.bank + '.param.2.guideva6') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva6") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('internetBanking_' + data.bank + '.param.2.guideva6') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("internetBanking_" + data.bank + ".param.2.guideva6") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva7'" v-if="$t('internetBanking_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('internetBanking_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva8'" v-if="$t('internetBanking_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'internetBanking_' + data.bank + '.guideva9'" v-if="$t('internetBanking_' + data.bank + '.guideva9') !== ''">
                        <span v-if="$t('internetBanking_' + data.bank + '.param.0.guideva9') !== '' ">
                          <span v-if="$t('internetBanking_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("internetBanking_" + data.bank + ".param.0.guideva9") }}</b>
                          </span>
                          <span v-else>{{ $t("internetBanking_" + data.bank + ".param.0.guideva9") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="card">
                <div 
                  id="heading4"
                  class="btn text-left collapsed"
                  data-toggle="collapse"
                  data-target="#collapse4"
                  aria-expanded="false"
                  aria-controls="collapse4"
                >
                  <div>{{ $t("paymentATM") }} {{ data.bank }}</div>
                  <img src="images/accordion.svg"/>
                </div>
                <div
                  id="collapse4"
                  class="collapse"
                  aria-labelledby="heading4"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva1'" v-if="$t('atmbank_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva2'" v-if="$t('atmbank_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva3'" v-if="$t('atmbank_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva4'" v-if="$t('atmbank_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('atmbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('atmbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("atmbank_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva5'" v-if="$t('atmbank_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva6'" v-if="$t('atmbank_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva7'" v-if="$t('atmbank_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('atmbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'atmbank_' + data.bank + '.guideva8'" v-if="$t('atmbank_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('atmbank_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('atmbank_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("atmbank_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("atmbank_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>
              <div class="card" v-if="data.bank == 'BRI'">
                <div 
                  id="heading5"
                  class="btn text-left collapsed"
                  data-toggle="collapse"
                  data-target="#collapse5"
                  aria-expanded="false"
                  aria-controls="collapse5"
                >
                  <div>Mini ATM {{ data.bank }}</div>
                  <img src="images/accordion.svg"/>
                </div>
                <div
                  id="collapse5"
                  class="collapse"
                  aria-labelledby="heading5"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva1'" v-if="$t('miniatm_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva2'" v-if="$t('miniatm_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva3'" v-if="$t('miniatm_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva4'" v-if="$t('miniatm_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('miniatm_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('miniatm_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("miniatm_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva5'" v-if="$t('miniatm_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva6'" v-if="$t('miniatm_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva7'" v-if="$t('miniatm_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('miniatm_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'miniatm_' + data.bank + '.guideva8'" v-if="$t('miniatm_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('miniatm_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('miniatm_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("miniatm_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("miniatm_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>

              <div class="card">
                <div 
                  id="heading3"
                  class="btn text-left collapsed" 
                  data-toggle="collapse"
                  data-target="#collapse3"
                  aria-expanded="false"
                  aria-controls="collapse3"
                >
                  <div>
                    {{ $t("otherBank") }}
                  </div>
                  <img src="images/accordion.svg"/>
                </div>
                <div
                  id="collapse3"
                  class="collapse"
                  aria-labelledby="heading3"
                  :data-parent="'#accordion-' + data.bank"
                >
                  <div class="card-body">
                    <ol>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva1'" v-if="$t('otherbank_' + data.bank + '.guideva1') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva1') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide1') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva1") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva1") }}</span>                          
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva2'" v-if="$t('otherbank_' + data.bank + '.guideva2') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva2') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide2') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva2') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide2') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva2") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva3'" v-if="$t('otherbank_' + data.bank + '.guideva3') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva3') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide3') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva3') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide3') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva3") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva3') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.2.boldguide3') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva3") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva3') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva3") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva4'" v-if="$t('otherbank_' + data.bank + '.guideva4') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva4') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide4') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva4') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide4') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva4") }}</span>
                          </span>
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva4') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.2.boldguide4') == 'y'">
                            <b v-if="$t('otherbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</b>
                            <b v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva4") }}</b>
                          </span>
                          <span v-else>
                            <span v-if="$t('otherbank_' + data.bank + '.param.2.guideva4') == 'prefix'">{{ data.prefix }}</span>
                            <span v-else>{{ $t("otherbank_" + data.bank + ".param.2.guideva4") }}</span>
                          </span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva5'" v-if="$t('otherbank_' + data.bank + '.guideva5') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva5') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide5') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva5') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide5') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva5") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva6'" v-if="$t('otherbank_' + data.bank + '.guideva6') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva6') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide6') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva6') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide6') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva6") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva7'" v-if="$t('otherbank_' + data.bank + '.guideva7') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva7') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide7') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</span>                          
                        </span>
                        <span v-if="$t('otherbank_' + data.bank + '.param.1.guideva7') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.1.boldguide7') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.1.guideva7") }}</span>
                        </span>
                      </i18n-t>
                      <i18n-t tag="li" v-bind:keypath="'otherbank_' + data.bank + '.guideva8'" v-if="$t('otherbank_' + data.bank + '.guideva8') !== ''">
                        <span v-if="$t('otherbank_' + data.bank + '.param.0.guideva8') !== '' ">
                          <span v-if="$t('otherbank_' + data.bank + '.param.0.boldguide8') == 'y'">
                            <b>{{ $t("otherbank_" + data.bank + ".param.0.guideva8") }}</b>
                          </span>
                          <span v-else>{{ $t("otherbank_" + data.bank + ".param.0.guideva8") }}</span>                          
                        </span>
                      </i18n-t>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div @click="close()" class="close-btn">
            {{ $t("closeBtn") }}
        </div>
    </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import useClipboard from 'vue-clipboard3'

export default {
    name: "Virtual Account",
    mixins: [Mixin],
    setup() {
      const { toClipboard } = useClipboard()

      const copy = async (bank) => {
        try {
          await toClipboard(bank)
          // this.$bvToast.toast(bank, {
          //   title: title,
          //   autoHideDelay: 5000,
          //   appendToast: false
          // })
          // console.log(bank)
        } catch (e) {
          console.error(e)
        }
      }

      return { copy }
    },
    data(){
        return{
            Global,
            VAinfo: {
              bank: "",
              accountNumber: '',
            },
            showBankList: false,
            virtualaccounts:  [
                {
                    "bank": "BCA",
                    "prefix": "14240",
                    "prefix2": "10000",
                    "digit": "18",
                    "customize": 8
                },
                {
                    "bank": "MANDIRI",
                    "prefix": "88908",
                    "prefix2": "83780",
                    "digit": "18",
                    "customize": 8
                },
                {
                    "bank": "BNI",
                    "prefix": "8808",
                    "prefix2": "83786",
                    "digit": "16",
                    "customize": 7
                },
                {
                    "bank": "PERMATA",
                    "prefix": "7293",
                    "prefix2": "83786",
                    "digit": "16",
                    "customize": 7
                },
                {
                    "bank": "BRI",
                    "prefix": "13282",
                    "prefix2": "83786",
                    "digit": "17",
                    "customize": 7
                }
            ]

        }
    },
    props: {
      opportunity: {
        type: String,
        default: ''
      },
      amount: {
        type: String,
        default: ''
      },
    },
    async created(){
      let vm = this;
      await vm.getVirtualAccount(); 
      vm.setVAinfo()
      // this.virtualaccounts = Global.VirtualAccount
    },
    methods: {
        close(){
            this.$emit('close')
        },
        
      changeBank(bank, prefix, prefix2, customize, policyNumber) {
         this.VAinfo.bank = bank;
         this.VAinfo.accountNumber =
         prefix + prefix2 + policyNumber.substr(-customize);
         this.$emit('changeBank',this.VAinfo)
      },
      makeToast(message) {
         var vm = this;
         vm.$toast.open({
            message: message,
            type: "info",
            duration: 5000,
            position: Global.platformId === 'ios' ? "bottom" : "top-right",
            dismissible: true
         })
      },
      setVAinfo(){
         if (Global.VirtualAccount.length > 0) {
            this.VAinfo.bank = Global.VirtualAccount[0].bank,
            this.VAinfo.accountNumber= Global.VirtualAccount[0].prefix + Global.VirtualAccount[0].prefix2 + this.opportunity.substr(-Global.VirtualAccount[0].customize)
            this.$emit('changeBank',this.VAinfo)
         }
      },
    },
}
</script>

<i18n>
    {
        "en" : {
            "copy" : "Copy",
            "copied" : "Copied",
            "chooseBank" : "Choose Bank",
            "vaLabel": "Nomor Virtual Account {bank}",
            "amountToBePaid" : "Price to be paid",
            "currency": "IDR ",
            "closeBtn" : "Close",
            "otherBank" : "Other Banks",
            "paymentATM" : "Payment via ATM Bank",
            "mbanking_BCA": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"m-BCA",
                    "boldguide2":"y",
                    "guideva3":"m-Transfer > BCA Virtual Account",
                    "boldguide3":"y",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"Confirm",
                    "boldguide5":"y",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Log in to BCA Mobile app.",
                "guideva2":"Select menu {0}, and enter access code to m-BCA.",
                "guideva3":"Select {0}.",
                "guideva4":"You can choose from Transfer List or enter XENDIT's virtual account number {0} and your policy number (for example : {1}123456).",
                "guideva5":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select {0}.",
                "guideva6":"Enter your m-BCA pin.",
                "guideva7":"Your transaction is complete! Please keep the notification as a proof of your transaction.",
                "guideva8":"",
                },
                "mbanking_MANDIRI": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"XENDIT's",
                    "boldguide3":"y",
                    "guideva4":"confirm",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Login to Livin' by Mandiri mobile app.",
                "guideva2":"Select Transfer or Payment.",
                "guideva3":"Enter {0} virtual account code {1} along with your policy number (for example: {2}123456).",
                "guideva4":"Check the data on screen, if the details are correct, select {0}.",
                "guideva5":"Enter your PIN Livin' by Mandiri mobile app.",
                "guideva6":"Your transaction is complete! Please keep your receipt as payment proof.",
                "guideva7":"",
                "guideva8":"",
                },
                "mbanking_BNI": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Transfer > Virtual Account Billing",
                    "boldguide3":"y",
                    "guideva4":"XENDIT’s",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Login to your BNI Mobile Banking on your phone.",
                "guideva2":"Enter your User ID and password.",
                "guideva3":"Select {0}.",
                "guideva4":"Enter {0} virtual account code ({1}) and your policy number (for example: {2}123456).",
                "guideva5":"Check your payment detail. If the detail is correct, enter your transaction password.",
                "guideva6":"Your transaction is complete! Please keep the notification as a proof of your transaction.",
                "guideva7":"",
                "guideva8":"",
                },
                "mbanking_PERMATA": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"Pay Bills > Virtual Account",
                    "boldguide2":"y",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Access your PermataMobile X application and enter your Password.",
                "guideva2":"Select {0}.",
                "guideva3":"Enter Xendit Virtual Account Number ({0}) and your policy number (for example: {1}123456).",
                "guideva4":"Your billing detail will show on the screen.",
                "guideva5":"Choose your source of payment.",
                "guideva6":"Enter SMS token response.",
                "guideva7":"Transaction completed.",
                "guideva8":"",
                },
                "mbanking_BRI": {
                "param":[
                    {
                    "guideva1":"BRI Mobile Banking",
                    "boldguide1":"y",
                    "guideva2":"Info > BRIVA",
                    "boldguide2":"y",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Login to the {0} application.",
                "guideva2":"Select {0}.",
                "guideva3":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
                "guideva4":"Check your billing details.",
                "guideva5":"If the details are correct, enter BRI Mobile/SMS Banking PIN to process.",
                "guideva6":"You will receive payment notification via SMS.",
                "guideva7":"",
                "guideva8":"",
                },
                "internetBanking_BCA": {
                "param":[
                    {
                    "guideva1":"https://ibank.klikbca.com/",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Transfer > Transfer to BCA Virtual Account",
                    "boldguide3":"y",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"Continue",
                    "boldguide5":"y",
                    "guideva6":"Send",
                    "boldguide6":"y",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"https://ibank.klikbca.com/",
                "guideva1":"Log in to KlikBCA Invidual app ({0}).",
                "guideva2":"Enter User ID and PIN.",
                "guideva3":"Select {0}.",
                "guideva4":"Enter Xendit's virtual account number {0} and your policy number. (for example : {1}123456).",
                "guideva5":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select {0}.",
                "guideva6":"Input your \"keyBCA Response\" and click {0}.",
                "guideva7":"Your transaction is complete! Print the reference number as proof of your transaction.",
                "guideva8":"",
                "guideva9":"",
                },
                "internetBanking_MANDIRI": {
                "param":[
                    {
                    "guideva1":"https://ibank.bankmandiri.co.id/",
                    "boldguide1":"",
                    "guideva2":"Payment > Multipayment",
                    "boldguide2":"y",
                    "guideva3":"Xendit",
                    "boldguide3":"y",
                    "guideva4":"Next",
                    "boldguide4":"y",
                    "guideva5":"Confirm",
                    "boldguide5":"y",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"https://ibank.bankmandiri.co.id/",
                "guideva1":"Go to Mandiri Online website ({0}), username dan password.",
                "guideva2":"Select menu {0}.",
                "guideva3":"Select Service Provider {0} {1}.",
                "guideva4":"Input Virtual Account number, then click {0}.",
                "guideva5":"Check the data on screen. If all details are correct, select {0}.",
                "guideva6":"You are required to approve your payment on Livin' by Mandiri mobile app.",
                "guideva7":"",
                "guideva8":"",
                "guideva9":"",
                },
                "internetBanking_BNI": {
                "param":[
                    {
                    "guideva1":"https://ibank.bni.co.id/",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Virtual Account Billing",
                    "boldguide3":"y",
                    "guideva4":"XENDIT’s",
                    "boldguide4":"y",
                    "guideva5":"Continue",
                    "boldguide5":"y",
                    "guideva6":"Continue",
                    "boldguide6":"y",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"https://ibank.bni.co.id/",
                "guideva1":"Login to {0}",
                "guideva2":"Enter your User ID and password.",
                "guideva3":"Select {0}.",
                "guideva4":"Enter {0} virtual account code ({1}) and your policy number (for example: {2}123456).",
                "guideva5":"Choose the type of account you are using to transfer, then select {0}.",
                "guideva6":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct click {0}.",
                "guideva7":"Input the authentication token code.",
                "guideva8":"Your transaction is complete!",
                "guideva9":"",
                },
                "internetBanking_PERMATA": {
                "param":[
                    {
                    "guideva1":"https://www.permatanet.com/",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Payment > Billing payment > Virtual Account",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"prefix",
                    "boldguide6":"y",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"prefix",
                    "boldguide6":"y",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"https://www.permatanet.com/",
                "guideva1":"Open the {0} website.",
                "guideva2":"Enter User ID & Password.",
                "guideva3":"Choose {0}.",
                "guideva4":"Choose your source of payment.",
                "guideva5":"Choose “add new billing”.",
                "guideva6":"Enter Xendit Virtual Account Number ({0}) and your policy number (for example: {1}123456).",
                "guideva7":"Check your billing detail.",
                "guideva8":"Enter SMS token response code.",
                "guideva9":"Transaction completed.",
                },
                "internetBanking_BRI": {
                "param":[
                    {
                    "guideva1":"https://ib.bri.co.id/ib-bri/",
                    "boldguide1":"",
                    "guideva2":"Payment menu > BRIVA",
                    "boldguide2":"y",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"https://ib.bri.co.id/ib-bri/",
                "guideva1":"Login to the BRI Internet Banking page {0}",
                "guideva2":"Select the {0}.",
                "guideva3":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
                "guideva4":"Enter BRI Internet Banking password.",
                "guideva5":"Enter BRI Internet Banking mToken.",
                "guideva6":"You will get a payment notification.",
                "guideva7":"",
                "guideva8":"",
                "guideva9":"",
                },
                "atmbank_BCA": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Insert your card and enter your PIN number.",
                "guideva2":"Select Other Transaction > Transfer > To BCA Virtual Account.",
                "guideva3":"Enter XENDIT's virtual account number {0} and your policy number. (for example : {1}123456).",
                "guideva4":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select yes.",
                "guideva5":"Your transaction is complete! Please keep your receipt as a proof of your transaction.",
                "guideva6":"",
                "guideva7":"",
                "guideva8":"",
                },
                "atmbank_MANDIRI": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"Payment/Purchase",
                    "boldguide2":"y",
                    "guideva3":"Others",
                    "boldguide3":"y",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"Correct",
                    "boldguide5":"y",
                    "guideva6":"“1”",
                    "boldguide6":"y",
                    "guideva7":"Yes",
                    "boldguide7":"y",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Multipayment",
                    "boldguide3":"y",
                    "guideva4":"Correct",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"Yes",
                    "boldguide6":"y",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Insert your card and enter your PIN.",
                "guideva2":"Select {0}.",
                "guideva3":"Select {0}, then select {1}.",
                "guideva4":"Input Xendit's code {0}, and choose {1}.",
                "guideva5":"Input your virtual account number, and choose {0}.",
                "guideva6":"Check the data on screen. If correct, press {0} and choose {1}.",
                "guideva7":"Check the data on screen again and choose {0}.",
                "guideva8":"Your transaction is complete! Keep your receipt for proof of payment.",
                },
                "atmbank_BNI": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"Other > Transfer > Saving Account",
                    "boldguide2":"y",
                    "guideva3":"Virtual Account Billing",
                    "boldguide3":"y",
                    "guideva4":"XENDIT’s",
                    "boldguide4":"y",
                    "guideva5":"Yes",
                    "boldguide5":"y",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Insert your card and enter PIN number.",
                "guideva2":"Select menu {0}.",
                "guideva3":"Select {0}.",
                "guideva4":"Enter {0} virtual account code ({1}) and your policy number (for example: {2}123456).",
                "guideva5":"Make sure your payment detail is correct such as VA code, name, and bill on the confirmation page. If the details are correct select {0}.",
                "guideva6":"Transaction complete! Please keep your receipt as a proof of your payment.",
                "guideva7":"",
                "guideva8":"",
                },
                "atmbank_PERMATA": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Other payments > Virtual Account",
                    "boldguide3":"y",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"correct",
                    "boldguide5":"y",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Insert your Permata ATM card & PIN.",
                "guideva2":"On the main menu, select Other Transactions.",
                "guideva3":"Select Payment > {0}.",
                "guideva4":"Enter your Xendit Virtual Account number ({0}) and your policy number (example: {1}123456).",
                "guideva5":"The amount to be paid and the account number will appear on the payment confirmation page. If the information is correct, select {0}.",
                "guideva6":"",
                "guideva7":"",
                "guideva8":"",
                },
                "atmbank_BRI": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"Other Transaction > Payment > Others > BRIVA",
                    "boldguide2":"y",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Insert your BRI ATM card and PIN.",
                "guideva2":"Select {0}.",
                "guideva3":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
                "guideva4":"Check your billing details.",
                "guideva5":"If the details are correct, select Yes to process the payment.",
                "guideva6":"",
                "guideva7":"",
                "guideva8":"",
                },
                "miniatm_BRI": {
                "param":[
                    {
                    "guideva1":"Mini ATM menu",
                    "boldguide1":"y",
                    "guideva2":"Payment > BRIVA",
                    "boldguide2":"y",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"Next",
                    "boldguide6":"y",
                    "guideva7":"Yes",
                    "boldguide7":"y",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Select {0}.",
                "guideva2":"Select {0}.",
                "guideva3":"Swipe ATM card.",
                "guideva4":"Enter your Xendit BRI Virtual Account Number ({0}) and your policy number (Example: {1}123456).",
                "guideva5":"Enter ATM pin.",
                "guideva6":"Click {0} to process payment.",
                "guideva7":"Click {0} to print the receipt.",
                "guideva8":"",
                },
                "otherbank_BCA": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Other Transaction > Transfer > Other Bank",
                    "boldguide3":"y",
                    "guideva4":"014",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Get your BCA VA number.",
                "guideva2":"Insert your card and enter your PIN number or your mBanking PIN number.",
                "guideva3":"Select {0}.",
                "guideva4":"Select to BCA Bank or input the bank code {0}.",
                "guideva5":"Enter the amount to be paid.",
                "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
                "guideva7":"",
                "guideva8":"",
                },
                "otherbank_MANDIRI": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Other Transaction > Transfer > Other Bank",
                    "boldguide3":"y",
                    "guideva4":"008",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Get your Mandiri VA number.",
                "guideva2":"Insert your card and enter your PIN number or your mBanking PIN number.",
                "guideva3":"Select {0}.",
                "guideva4":"Select to Mandiri Bank or input the bank code {0}.",
                "guideva5":"Enter the amount to be paid.",
                "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
                "guideva7":"",
                "guideva8":"",
                },
                "otherbank_BNI": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Other Transaction > Transfer > Other Bank",
                    "boldguide3":"y",
                    "guideva4":"009",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Get your BNI VA number.",
                "guideva2":"Insert your card and enter your PIN number or your mBanking PIN number.",
                "guideva3":"Select {0}.",
                "guideva4":"Select to BNI Bank or input the bank code {0}.",
                "guideva5":"Enter the amount to be paid.",
                "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
                "guideva7":"",
                "guideva8":"",
                },
                "otherbank_PERMATA": {
                "param":[
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"Transfer",
                    "boldguide2":"y",
                    "guideva3":"Select Permata Bank or",
                    "boldguide3":"y",
                    "guideva4":"Enter the amount to be paid",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"Transfer to Other Banks",
                    "boldguide2":"y",
                    "guideva3":"013",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"prefix",
                    "boldguide3":"y",
                    "guideva4":"",
                    "boldguide4":"",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Get your Permata VA number.",
                "guideva2":"Select the {0} menu > {1}.",
                "guideva3":"{0} enter Permata Bank code ({1}) and payment code ({2}123456).",
                "guideva4":"{0}.",
                "guideva5":"Check the data on the screen. If the details are correct, complete your transaction.",
                "guideva6":"",
                "guideva7":"",
                "guideva8":"",
                },
                "otherbank_BRI": {
                "param":[
                    {
                    "guideva1":"BRI",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"Transfer menu > Transfer to Other Banks",
                    "boldguide3":"y",
                    "guideva4":"Select BRI bank or",
                    "boldguide4":"y",
                    "guideva5":"Enter the amount to be paid",
                    "boldguide5":"y",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"002",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    },
                    {
                    "guideva1":"",
                    "boldguide1":"",
                    "guideva2":"",
                    "boldguide2":"",
                    "guideva3":"",
                    "boldguide3":"",
                    "guideva4":"prefix",
                    "boldguide4":"y",
                    "guideva5":"",
                    "boldguide5":"",
                    "guideva6":"",
                    "boldguide6":"",
                    "guideva7":"",
                    "boldguide7":"",
                    "guideva8":"",
                    "boldguide8":"",
                    }
                ],
                "link":"",
                "guideva1":"Get your VA {0} number.",
                "guideva2":"Insert you ATM card and enter your PIN or mBanking PIN number",
                "guideva3":"Select the {0}.",
                "guideva4":"{0} enter BRI bank code ({1}) and payment code ({2}123456).",
                "guideva5":"{0}.",
                "guideva6":"Check the data on the screen. If the details are correct, complete your transaction.",
                "guideva7":"",
                "guideva8":"",
                },
        },
        "id" : {
            "copy" : "Salin",
            "copied" : "Tersalin",
            "chooseBank" : "Pilih Bank",
            "vaLabel": "Virtual Account Number {bank}",
            "amountToBePaid" : "Tagihan yang harus dibayar",
            "currency": "Rp",
            "closeBtn" : "Tutup",
            "otherBank" : "Bank Lain",
            "paymentATM" : "Pembayaran melalui ATM bank",
            "mbanking_BCA": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"m-BCA",
                "boldguide2":"y",
                "guideva3":"m-Transfer > BCA Virtual Account",
                "boldguide3":"y",
                "guideva4":"XENDIT",
                "boldguide4":"y",
                "guideva5":"Konfirmasi",
                "boldguide5":"y",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Lakukan log in pada aplikasi BCA Mobile.",
            "guideva2":"Pilih menu {0}, kemudian masukkan kode akses m-BCA.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Pilih dari Daftar Transfer, atau masukkan nomor {0} {1} dan Nomor Polis kamu (Contoh: {2}123456).",
            "guideva5":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
            "guideva6":"Masukkan pin m-BCA kamu.",
            "guideva7":"Transaksi selesai! Kamu bisa menyimpan notifikasi yang muncul sebagai bukti pembayaran.",
            "guideva8":"",
            },
            "mbanking_MANDIRI": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Xendit",
                "boldguide3":"y",
                "guideva4":"Konfirmasi",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Login Aplikasi Livin' by Mandiri.",
            "guideva2":"Pilih menu Transfer/Bayar.",
            "guideva3":"Masukkan kode virtual account {0} {1}, dan nomor polis kamu (contoh: {2}123456).",
            "guideva4":"Cek detail pembayaran. Jika detail pembayaran sudah benar, pilih {0}.",
            "guideva5":"Masukkan PIN aplikasi Livin' by Mandiri.",
            "guideva6":"Transaksi selesai! Simpan struk transaksi kamu sebagai bukti pembayaran.",
            "guideva7":"",
            "guideva8":"",
            },
            "mbanking_BNI": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Transfer > Virtual Account Billing",
                "boldguide3":"y",
                "guideva4":"XENDIT",
                "boldguide4":"y",
                "guideva5":"Lanjut",
                "boldguide5":"y",
                "guideva6":"Lanjutkan",
                "boldguide6":"y",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Akses BNI Mobile Banking melalui handphone.",
            "guideva2":"Masukkan User ID dan password.",
            "guideva3":"Pilih menu {0}.",
            "guideva4":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
            "guideva5":"Cek detail pembayaran dan masukkan password mobile banking kamu.",
            "guideva6":"Transaksi selesai! Kamu bisa menyimpan notifikasi yang muncul sebagai bukti pembayaran.",
            "guideva7":"",
            "guideva8":"",
            },
            "mbanking_PERMATA": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"Bayar Tagihan > Virtual Account",
                "boldguide2":"y",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Buka aplikasi PermataMobile X dan masukkan Password kamu.",
            "guideva2":"Pilih {0}.",
            "guideva3":"Masukkan Nomor Virtual Account Xendit ({0}) dan nomor polis kamu (Contoh: {1}123456).",
            "guideva4":"Detail tagihan akan muncul dilayar.",
            "guideva5":"Pilih sumber pembayaran.",
            "guideva6":"Masukan SMS token response.",
            "guideva7":"Transaksi selesai.",
            "guideva8":"",
            },
            "mbanking_BRI": {
            "param":[
                {
                "guideva1":"Mobile Banking BRI",
                "boldguide1":"y",
                "guideva2":"Info > BRIVA",
                "boldguide2":"y",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Login pada aplikasi {0}.",
            "guideva2":"Pilih menu {0}.",
            "guideva3":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
            "guideva4":"Cek detail tagihan dan pastikan tagihan kamu sudah benar.",
            "guideva5":"Masukkan PIN Mobile/SMS Banking BRI.",
            "guideva6":"Anda akan mendapatkan notifikasi pembayaran melalui SMS.",
            "guideva7":"",
            "guideva8":"",
            },
            "internetBanking_BCA": {
            "param":[
                {
                "guideva1":"https://ibank.klikbca.com/",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Transfer Dana > Transfer ke BCA Virtual Account",
                "boldguide3":"y",
                "guideva4":"XENDIT",
                "boldguide4":"y",
                "guideva5":"Lanjutkan",
                "boldguide5":"y",
                "guideva6":"Kirim",
                "boldguide6":"y",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"https://ibank.klikbca.com/",
            "guideva1":"Lakukan log in pada aplikasi KlikBCA Individual ({0}).",
            "guideva2":"Masukkan User ID dan PIN.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Masukkan nomor BCA nomor virtual account {0} {1} dan Nomor Polis kamu (Contoh: {2}123456).",
            "guideva5":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
            "guideva6":"Masukkan nomor dari token kamu dan klik {0}.",
            "guideva7":"Transaksi selesai! Cetak nomor referensi sebagai bukti transaksi kamu.",
            "guideva8":"",
            "guideva9":"",
            },
            "internetBanking_MANDIRI": {
            "param":[
                {
                "guideva1":"https://ibank.bankmandiri.co.id/",
                "boldguide1":"",
                "guideva2":"Pembayaran > Multipayment",
                "boldguide2":"y",
                "guideva3":"Xendit",
                "boldguide3":"y",
                "guideva4":"Lanjut",
                "boldguide4":"y",
                "guideva5":"Konfirmasi",
                "boldguide5":"y",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"https://ibank.bankmandiri.co.id/",
            "guideva1":"Akses situs Mandiri Online ({0}), masukkan username dan password.",
            "guideva2":"Pilih menu {0}.",
            "guideva3":"Pilih Penyedia Jasa {0} {1}.",
            "guideva4":"Masukkan nomor virtual account Mandiri dan klik {0}.",
            "guideva5":"Layar akan menampilkan konfirmasi. Apabila sesuai, lanjutkan dengan pilih {0}.",
            "guideva6":"Kamu diharuskan melakukan persetujuan pembayaran diaplikasi Livin' by Mandiri di mobile phone.",
            "guideva7":"",
            "guideva8":"",
            "guideva9":"",
            },
            "internetBanking_BNI": {
            "param":[
                {
                "guideva1":"https://ibank.bni.co.id/",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Virtual Account Billing",
                "boldguide3":"y",
                "guideva4":"XENDIT",
                "boldguide4":"y",
                "guideva5":"Lanjut",
                "boldguide5":"y",
                "guideva6":"Lanjutkan",
                "boldguide6":"y",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"https://ibank.bni.co.id/",
            "guideva1":"Akses {0}",
            "guideva2":"Masukkan User ID dan password.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
            "guideva5":"Pilih rekening yang kamu gunakan, lalu pilih {0}.",
            "guideva6":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
            "guideva7":"Masukkan Kode Otentikasi Token.",
            "guideva8":"Transaksi selesai!",
            "guideva9":"",
            },
            "internetBanking_PERMATA": {
            "param":[
                {
                "guideva1":"https://www.permatanet.com/",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Pembayaran Tagihan > Virtual Account",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"XENDIT",
                "boldguide6":"y",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"prefix",
                "boldguide6":"y",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"prefix",
                "boldguide6":"y",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"https://www.permatanet.com/",
            "guideva1":"Buka webitse {0}",
            "guideva2":"Masukan User ID & Password.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Pilih sumber pembayaran.",
            "guideva5":"Pilih menu “masukkan daftar tagihan baru”.",
            "guideva6":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
            "guideva7":"Cek detail tagihan dan pastikan sudah sesuai.",
            "guideva8":"Masukan SMS token response.",
            "guideva9":"Transaksi selesai.",
            },
            "internetBanking_BRI": {
            "param":[
                {
                "guideva1":"https://ib.bri.co.id/ib-bri/",
                "boldguide1":"",
                "guideva2":"Pembayaran > BRIVA",
                "boldguide2":"y",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"https://ib.bri.co.id/ib-bri/",
            "guideva1":"Login pada halaman Internet Banking BRI {0}",
            "guideva2":"Pilih menu {0}.",
            "guideva3":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
            "guideva4":"Masukkan password Internet Banking BRI.",
            "guideva5":"Masukkan mToken Internet Banking BRI.",
            "guideva6":"Anda akan mendapatkan notifikasi pembayaran.",
            "guideva7":"",
            "guideva8":"",
            "guideva9":"",
            },
            "atmbank_BCA": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"XENDIT",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Masukkan Kartu ATM BCA & PIN.",
            "guideva2":"Pilih menu Transaksi Lainnya > Transfer > ke Rekening BCA Virtual Account.",
            "guideva3":"Masukkan nomor virtual account {0} {1} dan Nomor Polis kamu (Contoh: {2}123456).",
            "guideva4":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih ya.",
            "guideva5":"Transaksi selesai! Simpan struk transaksi kamu sebagai bukti pembayaran.",
            "guideva6":"",
            "guideva7":"",
            "guideva8":"",
            },
            "atmbank_MANDIRI": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"Bayar/Beli",
                "boldguide2":"y",
                "guideva3":"Lainnya",
                "boldguide3":"y",
                "guideva4":"Xendit",
                "boldguide4":"y",
                "guideva5":"Benar",
                "boldguide5":"y",
                "guideva6":"“1”",
                "boldguide6":"y",
                "guideva7":"Ya",
                "boldguide7":"y",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Multipayment",
                "boldguide3":"y",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"Ya",
                "boldguide6":"y",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"Benar",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Masukkan kartu ATM dan Pin.",
            "guideva2":"Pilih Menu {0}.",
            "guideva3":"Pilih menu {0}, hingga menemukan menu {1}.",
            "guideva4":"Masukkan kode {0} {1}, lalu pilih {2}.",
            "guideva5":"Masukkan nomor virtual account kamu, lalu pilih tombol {0}.",
            "guideva6":"Masukkan angka {0} untuk memilih tagihan, lalu pilih tombol {1}.",
            "guideva7":"Akan muncul konfirmasi pembayaran, lalu pilih tombol {0}.",
            "guideva8":"Transaksi selesai! Kamu bisa simpan struk sebagai bukti pembayaran kamu.",
            },
            "atmbank_BNI": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"Lainnya > Transfer > Rekening Tabungan",
                "boldguide2":"y",
                "guideva3":"Virtual Account Billing",
                "boldguide3":"y",
                "guideva4":"XENDIT",
                "boldguide4":"y",
                "guideva5":"Ya",
                "boldguide5":"y",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Masukkan kartu dan pin ATM kamu.",
            "guideva2":"Pilih Menu {0}.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Masukkan kode virtual account {0} ({1}) dan nomor polis kamu (Contoh: {2}123456).",
            "guideva5":"Di halaman konfirmasi, pastikan detail pembayaran sudah sesuai seperti kode VA, Nama, dan Total Tagihan. Jika sudah sesuai, pilih {0}.",
            "guideva6":"Transaksi selesai! Simpan struk transaksi kamu sebagai bukti pembayaran.",
            "guideva7":"",
            "guideva8":"",
            },
            "atmbank_PERMATA": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"Transaksi Lainnya",
                "boldguide2":"y",
                "guideva3":"Pembayaran > Pembayaran lainnya > Virtual Account",
                "boldguide3":"y",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"Benar",
                "boldguide5":"y",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Masukkan kartu ATM Permata & PIN kamu.",
            "guideva2":"Pada menu utama, pilih {0}.",
            "guideva3":"Pilih menu {0}.",
            "guideva4":"Masukkan nomor Virtual Account Xendit ({0}) dan nomor polis kamu (Contoh: {1}123456).",
            "guideva5":"Jumlah yang harus dibayar dan nomor rekening akan muncul pada halaman konfirmasi pembayaran. Jika informasi sudah benar, pilih {0}.",
            "guideva6":"",
            "guideva7":"",
            "guideva8":"",
            },
            "atmbank_BRI": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"Transaksi Lain > Pembayaran > Lainnya > BRIVA",
                "boldguide2":"y",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"Ya",
                "boldguide5":"y",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Masukkan kartu ATM BRI dan PIN kamu.",
            "guideva2":"Pilih menu {0}.",
            "guideva3":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
            "guideva4":"Cek detail tagihan dan pastikan detail tagihan kamu sudah benar.",
            "guideva5":"Pilih {0} untuk memproses pembayaran.",
            "guideva6":"",
            "guideva7":"",
            "guideva8":"",
            },
            "miniatm_BRI": {
            "param":[
                {
                "guideva1":"Mini ATM",
                "boldguide1":"y",
                "guideva2":"Pembayaran > BRIVA",
                "boldguide2":"y",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"y",
                "guideva6":"Lanjut",
                "boldguide6":"y",
                "guideva7":"Ya",
                "boldguide7":"y",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Pilih menu {0}.",
            "guideva2":"Pilih menu {0}.",
            "guideva3":"Swipe kartu ATM.",
            "guideva4":"Masukkan Nomor Virtual Account Xendit BRI ({0}) dan nomor polis kamu (Contoh: {1}123456).",
            "guideva5":"Masukkan pin ATM.",
            "guideva6":"Klik {0} untuk memproses pembayaran.",
            "guideva7":"Klik {0} untuk mencetak struk.",
            "guideva8":"",
            },
            "otherbank_BCA": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Transaksi Lain > Transfer > Bank lain",
                "boldguide3":"y",
                "guideva4":"014",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Salin nomor VA BCA kamu.",
            "guideva2":"Masukkan kartu ATM dan PIN atau PIN mBanking kamu.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Pilih bank BCA atau masukkan kode bank {0}.",
            "guideva5":"Masukkan nominal yang harus dibayarkan.",
            "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
            "guideva7":"",
            "guideva8":"",
            },
            "otherbank_MANDIRI": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Transaksi Lain > Transfer > Bank lain",
                "boldguide3":"y",
                "guideva4":"008",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Dapatkan nomor VA Mandiri kamu.",
            "guideva2":"Masukkan kartu ATM dan PIN atau PIN mBanking kamu.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Pilih bank Mandiri atau masukkan kode bank {0}.",
            "guideva5":"Masukkan nominal yang harus dibayarkan.",
            "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
            "guideva7":"",
            "guideva8":"",
            },
            "otherbank_BNI": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Transaksi Lain > Transfer > Bank lain",
                "boldguide3":"y",
                "guideva4":"009",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Dapatkan nomor VA BNI kamu.",
            "guideva2":"Masukkan kartu ATM dan PIN atau PIN mBanking kamu.",
            "guideva3":"Pilih {0}.",
            "guideva4":"Pilih bank BNI atau masukkan kode bank {0}.",
            "guideva5":"Masukkan nominal yang harus dibayarkan.",
            "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
            "guideva7":"",
            "guideva8":"",
            },
            "otherbank_PERMATA": {
            "param":[
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"Transfer > Transfer ke Bank Lain",
                "boldguide2":"y",
                "guideva3":"Pilih Bank Permata atau",
                "boldguide3":"y",
                "guideva4":"Masukkan nominal yang harus dibayarkan",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"013",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"prefix",
                "boldguide3":"y",
                "guideva4":"",
                "boldguide4":"",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Dapatkan nomor VA permata kamu.",
            "guideva2":"Pilih menu {0}.",
            "guideva3":"{0} masukan kode Permata Bank ({1}) dan kode bayar ({2}123456).",
            "guideva4":"{0}.",
            "guideva5":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
            "guideva6":"",
            "guideva7":"",
            "guideva8":"",
            },
            "otherbank_BRI": {
            "param":[
                {
                "guideva1":"BRI",
                "boldguide1":"y",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"Transfer > Transfer ke Bank Lain",
                "boldguide3":"y",
                "guideva4":"Pilih bank BRI atau",
                "boldguide4":"y",
                "guideva5":"Masukkan nominal yang harus dibayarkan",
                "boldguide5":"y",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"BRI (002)",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                },
                {
                "guideva1":"",
                "boldguide1":"",
                "guideva2":"",
                "boldguide2":"",
                "guideva3":"",
                "boldguide3":"",
                "guideva4":"prefix",
                "boldguide4":"y",
                "guideva5":"",
                "boldguide5":"",
                "guideva6":"",
                "boldguide6":"",
                "guideva7":"",
                "boldguide7":"",
                "guideva8":"",
                "boldguide8":"",
                }
            ],
            "link":"",
            "guideva1":"Dapatkan nomor VA {0} kamu.",
            "guideva2":"Masukkan kartu ATM dan nomor PIN kamu atau PIN mBanking kamu.",
            "guideva3":"Pilih menu {0}.",
            "guideva4":"{0} masukan kode {1} dan nomor polis kamu (Contoh: {2}123456).",
            "guideva5":"{0}.",
            "guideva6":"Cek data di layar. Jika detail sudah benar, selesaikan transaksimu.",
            "guideva7":"",
            "guideva8":"",
            },
        },
    }
</i18n>