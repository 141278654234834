<style>
.fullPaymentOnly {
  width: 100% !important;
}

#illness-payment #payment-accept-modal .scrollbar-default {
  scrollbar-width: thin;
  scrollbar-color: #ddd orange;
}
#illness-payment #payment-accept-modal .scrollbar-default::-webkit-scrollbar {
  width: 12px;
}
#illness-payment
  #payment-accept-modal
  .scrollbar-default::-webkit-scrollbar-track {
  background: #cecece;
  border-radius: 20px;
}
#illness-payment
  #payment-accept-modal
  .scrollbar-default::-webkit-scrollbar-thumb {
  background-color: orange;
  border-radius: 20px;
}

#illness-payment #payment-accept-modal #disclaimer {
  height: 60vh;
  overflow-y: auto;
  font-size: 14px;
}

#illness-payment #payment-accept-modal #disclaimer > div {
  text-indent: 20px;
}

.btn.disabled, .btn:disabled{
  border: 1px solid #ccc !important;
  color: #ccc !important;
  background-color: #FFF !important;
  box-shadow: 1px 1px 6px 1px rgb(0 0 0 / 15%) !important;
}

@media (max-width: 450px) {
  .h1section-text2.h4 {
    font-size: 18px;
  }
  body.default-style .insure-modal .modal-content .modal-footer button {
    font-size: 14px;
  }
}


.tooltip-pymt {
  position: relative;
  display: inline-block;
}

.tooltip-pymt .tooltiptext-pymt {
  visibility: hidden;
  width: 140px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}
.nav-tabs{
  border-bottom: 3px solid #dee2e6;
}
.nav-tabs .nav-item{
  margin-bottom: -3px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  border: 3px solid transparent;
}
.nav-link.active{
  color: #01549b !important;
}
.nav-link{
  color: #999 !important;
}
body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item .nav-link.active:before{
  content: none;
}
.btn-choose.active{
  border-radius: 5px !important;
  color: #fff !important;
  background-color: #01549b !important;
  border: 1px solid #01549b !important;
  box-shadow: none;
  background-image: none;
}
.btn-choose{
  border-radius: 5px !important;
  color: #003b71 !important;
  border: 1px solid #ccc;
  box-shadow: none;
  background-image: none;
}
body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency {
  margin:0 auto;
  padding:10px;
  background-color:#ffffff;
  border-radius:.25rem;
  border:1px solid rgba(221,221,221,100%)
  /* #dcebf5 */
}

body.default-style #illness-payment .payment-method-content .price-card{
  border:none;
  background-color:#ffffff;
  border-radius:.25rem;
  max-width:500px;
  margin:0 auto
}

.nav-tabs .nav-link {
  cursor: pointer;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:not(.active):hover {
  border-color:  transparent;
  color: #003b71;
}
/* body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info{
  margin:10px auto;
  border:none;
  background-image:linear-gradient(0deg,#16416a 0,#2b82d3 100%);
  color:#fff;
  box-shadow:0 2px 10px 1px rgba(0,0,0,.1);max-width:500px} */
body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info{
  margin:10px auto;
  border:none;
  background-image:linear-gradient(0deg,#dcebf5 0,#dcebf5 100%);
  background-color:#dcebf5;
  color:#003b71;
  box-shadow:0 2px 10px 1px rgba(0,0,0,.1);
  max-width:500px
}

#payment-frequency {
  width: 100%;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #what-cvv, body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #what-cvv:focus {
  padding: 2px 4px;
  line-height: 1;
  font-size: 10px;
  border-radius: 50%;
  background-color: transparent;
  width: 15px;
  color: #003b71;
  border-color: #fff;
}
body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #cvv-icon{
    position: absolute;
    right: 25px;
    top: 30px;
    color: #ccc;
}
@media (max-width: 451px){
  body.default-style #illness-payment .payment-method-content #credit-debit-method #card-info .form-group #cvv-icon{
    position: absolute;
    right: 25px;
    top: 48px;
    color: #ccc;
  }
}


.tooltip-pymt .tooltiptext-pymt::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-pymt:hover .tooltiptext-pymt {
  visibility: visible;
  opacity: 1;
}
@media (max-width: 370px) {
  .no-va{
    font-size: 0.8rem;
  }
  .btn{
    padding: 0.15rem 0.35rem;
  }
}
@media (max-width: 415px) {
  .img-bank{
    width: 80px;
  }
}

@media (max-width: 500px) {
  .text-debit-card{
    word-spacing:9999px;
  }
}

.btn-epolicy{
  background-color: #ff5011;
  border-radius: 5px;
}

.btn-epolicy-no{
  color: #ff5011 !important;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ff5011 !important;
  border-color: #ff5011 !important;
  /* padding: 5px 10px; */
}
.va-method .va-link.active, .va-method .show>.va-link{
    color: #003b71;
    background-color: transparent;
    border: 3px solid #003b71;
    transition: 0.5s;
}
.va-method .va-link{
    color: #003b71;
    background-color: transparent;
    border: 3px solid #c4c4c4;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs {
  margin-left: -10px;
  margin-right: -10px;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item {
  width: 50%;
  margin: 0;
  padding: 0 10px;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item:first-child {
  margin: 0;
}

body.default-style #illness-payment .payment-method-content #credit-debit-method #payment-frequency .nav-tabs .nav-item .nav-link {
    padding-top: 16px;
    padding-bottom: 16px; 
}
</style>

<template>
  <div id="illness-payment" class="py-5 center-container">
    <div class="blue-bg" v-if="Global.quote.opportunityNumber.substring(0,3) == 'TBA'">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 text-center pt-5">
                        <h3 class="text-center mb-5">{{$t("errorTBAtitle")}}</h3>
                        <div class="thank2you">
                            <h4 class="text-center h5 pt-2">
                                {{$t("errorTBAtext")}}
                            </h4>
                        </div>

                        <div class="text-center" style="margin: 3vh auto 0; background-image:url('images/bg-first.png'); background-repeat: no-repeat; background-position: bottom right; min-height:100px;" >
                            <button id="arrow2next" class="btn btn-outline" style="cursor:pointer;" v-if="false">
                                <h2 class="text-default animated2fast bounce2fast">&#8595;</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    <div class="container" v-else>
      <div class="row">
        <div class="col-12">
          <h6 id="choose-method-label" class="text-center fade show mb-3">
            {{ paymentTitle }}
          </h6>
          <h6 class="text-center fade show mb-3 mt-5" v-if="Global.tp === 'rec'">
            {{$t("instalmentNo", {instalmentNo: instalmentNo})}}
          </h6>
          <ul
            class="nav nav-tabs mb-3 mx-md-5"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item col d-flex flex-nowrap" style="padding-left: 0px; padding-right: 0px; flex-basis: 33%;" v-if="!Global.payment.isPmntInitiatedBySF || Global.opp.paymentMethod == 'Credit Card' || Global.opp.paymentMethod == ''">
              <a
                class="nav-link text-center flex-grow-1"
                v-bind:class="{'active' : (!Global.payment.isPmntInitiatedBySF && Global.opp.paymentMethod == 'Credit Card') || Global.opp.paymentMethod == 'Credit Card' || Global.paymentMethod == 'Credit Card'}"
                id="pills-credit-tab"
                data-selenium-name="payment-method"
                data-selenium-value="CC"
                data-toggle="pill"
                role="tab"
                aria-controls="credit-method-method"
                aria-selected="true"
                @click="Global.paymentMethod = 'Credit Card';automatic_debit()"
              >
                <!-- <span class="glyphicons glyphicons-credit-card" style="font-size:1rem"></span> -->
                <strong class="text-debit-card" style="font-size:1rem"> {{ $t("cc") }} </strong>
              </a>
            </li>
            <!-- <li class="nav-item col d-flex flex-nowrap" style="padding-left: 0px; padding-right: 0px; flex-basis: 33%;" v-if="!Global.payment.isPmntInitiatedBySF || Global.opp.paymentMethod == 'Debit Card' || Global.opp.paymentMethod == ''">
              <a
                class="nav-link text-center flex-grow-1"
                id="pills-debit-tab"
                v-bind:class="{'active' : (!Global.payment.isPmntInitiatedBySF && Global.paymentMethod == 'Debit Card') || Global.opp.paymentMethod == 'Debit Card' || Global.paymentMethod == 'Debit Card'}"
                data-selenium-name="payment-method"
                data-selenium-value="debit"
                data-toggle="pill"
                role="tab"
                aria-controls="debit-method-method"
                aria-selected="false"
                @click="Global.paymentMethod = 'Debit Card';automatic_debit()"
              >
                <strong class="text-debit-card" style="font-size:1rem"> {{ $t("debit_card") }} </strong>
              </a>
            </li> -->
            <li class="nav-item col d-flex flex-nowrap" style="padding-left: 0px; padding-right: 0px; flex-basis: 33%" v-if="!Global.payment.isPmntInitiatedBySF || Global.opp.paymentMethod == 'Virtual Account' || Global.opp.paymentMethod == ''">
              <a
                class="nav-link text-center flex-grow-1"
                v-bind:class="{'active' : (!Global.payment.isPmntInitiatedBySF && Global.paymentMethod == 'Virtual Account') || Global.opp.paymentMethod == 'Virtual Account' || Global.paymentMethod == 'Virtual Account'}"
                id="pills-ebanking-tab"
                data-selenium-name="payment-method"
                data-selenium-value="IB"
                data-toggle="pill"
                role="tab"
                aria-controls="ebanking-method"
                aria-selected="false"
                @click="Global.paymentMethod = 'Virtual Account';"
              >
                <!-- <span class="glyphicons glyphicons-global" style="font-size:1rem"></span> -->
                <strong class="text-debit-card" style="font-size:1rem"> Virtual Account </strong>
              </a>
            </li>
          </ul>

          <div
            class="tab-content payment-method-content mx-md-5"
            id="pills-tabContent"
          >
            <div
              class="tab-pane fade show active"
              id="credit-debit-method"
              role="tabpanel"
              aria-labelledby="credit-debit-method-tab"
            >
              <div id="payment-frequency">
                <ul
                  class="nav nav-tabs"
                  id="payment-option-tab"
                  role="tablist"
                  v-show="
                    Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor
                      ?.value > 0
                  "
                >
                  <li v-if="!Global.payment.isPmntInitiatedBySF || ('Yearly' == Global.paymentFrequency && Global.payment.isPmntInitiatedBySF) || Global.opp.paymentMethod == ''"
                    class="nav-item text-center col d-flex flex-nowrap"
                    v-bind:class="{
                      fullPaymentOnly: (!isAllowInstallment || !AvailableInstallment.VA)
                    }"
                  >
                    <a class="nav-link btn-choose flex-grow-1"
                      v-bind:class="{'active' : Global.paymentFrequency == 'Yearly'}"
                      id="full-pay-tab"
                      data-selenium-name="paymentFrequency"
                      data-selenium-value="full"
                      data-toggle="pill"
                      href="#full-pay"
                      role="tab"
                      aria-controls="payment-frequency-tabContent"
                      aria-selected="true"
                      v-on:click="Global.paymentFrequency = 'Yearly';"
                      >{{ $t("full_mayment") }}</a
                    >
                  </li>
                  <li class="nav-item text-center col d-flex flex-nowrap" v-if="isAllowInstallment && (!Global.payment.isPmntInitiatedBySF || (('12 months, no deposit' == Global.paymentFrequency || Global.opp.paymentFrequency == '') && Global.payment.isPmntInitiatedBySF))">
                    <a
                      class="nav-link btn-choose flex-grow-1"
                      v-bind:class="{'active' : Global.paymentFrequency == '12 months, no deposit'}"
                      id="installment-pay-tab"
                      data-selenium-name="paymentFrequency"
                      data-selenium-value="12 months"
                      data-toggle="pill"
                      href="#installment-pay"
                      role="tab"
                      aria-controls="installment-pay"
                      aria-selected="false"
                      v-on:click="Global.paymentFrequency = '12 months, no deposit'; automatic_debit()"
                      >{{ $t("installment") }}</a
                    >
                  </li>
                  <!-- <li class="nav-item text-center col" v-if="(AvailableInstallment.VA == true && Global.paymentMethod == 'Virtual Account') && (!Global.payment.isPmntInitiatedBySF || (('12 months, no deposit' == Global.paymentFrequency || Global.opp.paymentFrequency == '')  && Global.payment.isPmntInitiatedBySF)) && (Global.opp.paymentMethod == 'Virtual Account' || Global.paymentMethod == 'Virtual Account')">
                    <a
                      class="nav-link btn-choose"
                      v-bind:class="{'active' : Global.paymentFrequency == '12 months, no deposit'}"
                      id="installment-pay-tab"
                      data-selenium-name="paymentFrequency"
                      data-selenium-value="12 months"
                      data-toggle="pill"
                      href="#installment-pay"
                      role="tab"
                      aria-controls="installment-pay"
                      aria-selected="false"
                      v-on:click="Global.paymentFrequency = '12 months, no deposit'; automatic_debit()"
                      >{{ $t("installment") }}</a
                    >
                  </li> -->
                </ul>
                <div id="payment-frequency-tabContent" class="tab-content mt-3" v-if="Global.tp == 'nb'">
                  <div
                    class="tab-pane fade"
                    v-bind:class="{'show active' : Global.paymentFrequency == 'Yearly'}"
                    id="full-pay"
                    role="tabpanel"
                    aria-labelledby="full-pay-tab"
                  >
                    <div class="card price-card">
                      <div class="card-body p-2 text-center">
                        <h6
                          class="mb-1"
                          data-selenium-name="full-total-price"
                          :data-selenium-value="formatNumber(totalPremium, 0)"
                        >
                          {{ $t("total_price") }}
                        </h6>
                        <h2 class="price-total my-1">
                          <small>{{$t("currency")}} </small> {{ formatNumber(totalPremium, 0) }}
                          <small>/ {{$t("year")}}</small>
                        </h2>
                        
                        <p
                          class="height-price m-0"
                          v-if="twelveMonthsNoDepositPremium > totalPremium"
                        >
                          {{ formatNumber(twelveMonthsNoDepositPremium, 0) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    v-bind:class="{'show active' : Global.paymentFrequency == '12 months, no deposit'}"
                    id="installment-pay"
                    role="tabpanel"
                    aria-labelledby="installment-pay-tab"
                  >
                    <div class="card price-card" v-if="!Global.isSuperCI">
                      <div class="card-body p-2 text-center" v-if="Global.quote.productType !== Global.enumProductType.PA && Global.quote.productType !== Global.enumProductType.HCP && Global.quote.productType !== Global.enumProductType.Health">
                        <h6 v-if="isFirstMonthInstallment"
                          data-selenium-name="first-installment"
                          :data-selenium-value="
                            formatNumber(firstInstallmentNoDepositPremium, 0)
                          "
                        >
                          {{ $t("first_installment") }}
                          <span class="first-installment-price"
                            ><small>{{$t("currency")}} </small
                            > {{
                              formatNumber(firstInstallmentNoDepositPremium, 0)
                            }}{{
                              Global.selectedPackages.Covid19 ? "*" : ""
                            }}</span
                          >
                        </h6>
                        <p
                          data-selenium-name="installment-payment"
                          :data-selenium-value="
                            formatNumber(twelveMonthsNoDepositPremium / 12, 0)
                          "
                        >
                          {{ $t("11_installment") }} <small>{{$t("currency")}} </small>
                          <strong> {{
                            formatNumber(twelveMonthsNoDepositPremium / 12, 0)
                          }}
                          </strong>
                          x {{ isFirstMonthInstallment ? '11' : '12'}} {{$t("months")}}
                        </p>
                        <h6 class="mb-0" data-selenium-name="total_12_months" :data-selenium-value="formatNumber(twelveMonthsNoDepositPremiumPlus)">
                          {{ $t("total_12_months") }}
                          <small>{{$t("currency")}} </small> {{ formatNumber(twelveMonthsNoDepositPremiumPlus) }}
                        </h6>
                        <p
                          v-if="Global.selectedPackages.Covid19"
                          class="mt-2"
                          data-selenium-name="covid-premium"
                          :data-selenium-value="
                            formatNumber(
                              calculatePrice(
                                Global.selectedPackages?.Covid19
                                  ?.premiumBeforeTaxes?.value
                              ),
                              0
                            )
                          "
                        >
                          <i18n-t tag="div" keypath="covidIncluded_in_first_instalment">
                            <span>
                              <small>{{$t("currency")}} </small><strong> {{ formatNumber( calculatePrice( Global.selectedPackages?.Covid19?.premiumBeforeTaxes?.value), 0) }}</strong>
                            </span>
                          </i18n-t>
                        </p>
                      </div>
                      <div class="card-body p-2 text-center" v-else>
                        <h6
                          data-selenium-name="installment-payment"
                          :data-selenium-value="
                            formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment, 0)
                          "
                          style="font-size:16px;"
                        >
                        <strong>
                          {{ $t("11_installment") }} </strong> 
                        </h6>
                        <h2 style="color:#ff5011">
                          <small>
                            {{$t("currency")}}
                          </small>
                          <strong class="price-total" style="font-size: 30px;"> <span> </span> {{formatNumber(twelveMonthsNoDepositPremium.monthlyInstallment, 0)}}</strong>
                          <small>
                             x 12 {{ $t("months") }}
                          </small>
                         
                        </h2>
                        
                        <h6 class="mb-0" data-selenium-name="total_12_months" :data-selenium-value="formatNumber(twelveMonthsNoDepositPremium.totalInstallment)">
                          {{ $t("total_12_months") }}
                          <small>{{$t("currency")}} </small> {{ formatNumber(twelveMonthsNoDepositPremium.totalInstallment) }}
                        </h6>
                      </div>
                    </div>
                    <div class="card price-card" v-else>
                      <div class="card-body p-2 text-center">
                        <p
                          data-selenium-name="installment-payment"
                          :data-selenium-value="
                            formatNumber(twelveMonthsNoDepositPremium, 0)
                          "
                        >
                          {{ $t("11_installment") }} <small>{{$t("currency")}} </small
                          > {{
                            formatNumber(twelveMonthsNoDepositPremium / 12, 0)
                          }} x 12
                          {{ $t("months") }}
                        </p>
                        <h6 class="mb-0">
                          {{ $t("total_12_months") }}
                          <small>{{$t("currency")}} </small> {{ formatNumber(twelveMonthsNoDepositPremium) }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="payment-frequency-tabContent" class="tab-content mt-3" v-if="Global.tp == 'ren'">
                  <div
                    class="tab-pane fade"
                    v-bind:class="{'show active' : Global.paymentFrequency == 'Yearly'}"
                    id="full-pay"
                    role="tabpanel"
                    aria-labelledby="full-pay-tab"
                  >
                    <div class="card price-card">
                      <div class="card-body p-2 text-center">
                        <h6
                          class="mb-1"
                          data-selenium-name="full-total-price"
                          :data-selenium-value="formatNumber(Global.renewalPrices.totalTransactionPremium, 0)"
                        >
                          {{ $t("total_price") }}
                        </h6>
                        <h2 class="price-total my-1">
                          <small>{{$t("currency")}} </small> {{ formatNumber(Global.renewalPrices.totalTransactionPremium, 0) }}
                          <small>/ {{$t("year")}}</small>
                        </h2>
                        
                        <p
                          class="height-price m-0"
                          v-if="twelveMonthsNoDepositPremium > totalPremium"
                        >
                          {{ formatNumber(twelveMonthsNoDepositPremium, 0) }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    v-bind:class="{'show active' : Global.paymentFrequency == '12 months, no deposit'}"
                    id="installment-pay"
                    role="tabpanel"
                    aria-labelledby="installment-pay-tab"
                  >
                    <div class="card price-card" v-if="!Global.isSuperCI">
                      <div class="card-body p-2 text-center" v-if="Global.quote.productType !== Global.enumProductType.PA && Global.quote.productType !== Global.enumProductType.HCP && Global.quote.productType !== Global.enumProductType.Health">
                        <h6 v-if="isFirstMonthInstallment"
                          data-selenium-name="first-installment"
                          :data-selenium-value="
                            formatNumber(Global.renewalPrices.twelveMonthlyInstallmentAmnt, 0)
                          "
                        >
                          {{ $t("first_installment") }}
                          <span class="first-installment-price"
                            ><small>{{$t("currency")}} </small
                            > {{
                              formatNumber(Global.renewalPrices.twelveMonthlyInstallmentAmnt, 0)
                            }}{{
                              Global.selectedPackages.Covid19 ? "*" : ""
                            }}</span
                          >
                        </h6>
                        <p
                          data-selenium-name="installment-payment"
                          :data-selenium-value="
                            formatNumber(twelveMonthsNoDepositPremium / 12, 0)
                          "
                        >
                          {{ $t("11_installment") }} <small>{{$t("currency")}} </small>
                          <strong> {{
                            formatNumber(twelveMonthsNoDepositPremium / 12, 0)
                          }}
                          </strong>
                          x {{ isFirstMonthInstallment ? '11' : '12'}} {{$t("months")}}
                        </p>
                        <h6 class="mb-0" data-selenium-name="total_12_months" :data-selenium-value="formatNumber(twelveMonthsNoDepositPremiumPlus)">
                          {{ $t("total_12_months") }}
                          <small>{{$t("currency")}} </small> {{ formatNumber(twelveMonthsNoDepositPremiumPlus) }}
                        </h6>
                        <p
                          v-if="Global.selectedPackages.Covid19"
                          class="mt-2"
                          data-selenium-name="covid-premium"
                          :data-selenium-value="
                            formatNumber(
                              calculatePrice(
                                Global.selectedPackages?.Covid19
                                  ?.premiumBeforeTaxes?.value
                              ),
                              0
                            )
                          "
                        >
                          <i18n-t tag="div" keypath="covidIncluded_in_first_instalment">
                            <span>
                              <small>{{$t("currency")}} </small><strong> {{ formatNumber( calculatePrice( Global.selectedPackages?.Covid19?.premiumBeforeTaxes?.value), 0) }}</strong>
                            </span>
                          </i18n-t>
                        </p>
                      </div>
                      <div class="card-body p-2 text-center" v-else>
                        <h6
                          data-selenium-name="installment-payment"
                          :data-selenium-value="
                            formatNumber(Global.renewalPrices.twelveMonthlyInstallmentAmnt, 0)
                          "
                          style="font-size:16px;"
                        >
                        <strong>
                          {{ $t("11_installment") }} </strong> 
                        </h6>
                        <h2 style="color:#ff5011">
                          <small>
                            {{$t("currency")}}
                          </small>
                          <strong class="price-total" style="font-size: 30px;"> <span> </span> {{formatNumber(Global.renewalPrices.twelveMonthlyInstallmentAmnt, 0)}}</strong>
                          <small>
                             x 12 {{ $t("months") }}
                          </small>
                         
                        </h2>
                        
                        <h6 class="mb-0" data-selenium-name="total_12_months" :data-selenium-value="formatNumber(Global.renewalPrices.totalTwelveMonthlyPremium)">
                          {{ $t("total_12_months") }}
                          <small>{{$t("currency")}} </small> {{ formatNumber(Global.renewalPrices.totalTwelveMonthlyPremium) }}
                        </h6>
                      </div>
                    </div>
                    <div class="card price-card" v-else>
                      <div class="card-body p-2 text-center">
                        <p
                          data-selenium-name="installment-payment"
                          :data-selenium-value="
                            formatNumber(twelveMonthsNoDepositPremium, 0)
                          "
                        >
                          {{ $t("11_installment") }} <small>{{$t("currency")}} </small
                          > {{
                            formatNumber(twelveMonthsNoDepositPremium / 12, 0)
                          }} x 12
                          {{ $t("months") }}
                        </p>
                        <h6 class="mb-0">
                          {{ $t("total_12_months") }}
                          <small>{{$t("currency")}} </small> {{ formatNumber(twelveMonthsNoDepositPremium) }}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="Global.paymentMethod=='Credit Card' || Global.opp.paymentMethod == 'Credit Card'" id="card-info" class="card mb-4">
                <div class="card-body">
                  <div class="card-logo">
                    <div class="row">
                      <div class="col-7">
                        <h6 class="my-1">{{ $t("card_info") }}</h6>
                      </div>
                      <div class="col-5 text-right">
                        <picture>
                          <source :srcset="MasterCard">
                          <img :src="MasterCard" style="height: 25px;">
                        </picture>

                        <picture>
                          <source :srcset="Visa">
                          <img :src="Visa" style="height: 25px;">
                        </picture>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="card-number">{{
                      $t("credit_card")
                    }}</label>
                    <input
                      type="tel"
                      data-selenium-name="card-number"
                      class="form-control card-number"
                      maxlength="19"
                      id="card-number"
                      name="card-number"
                      aria-describedby="card-number"
                      placeholder=".... .... .... ...."
                      v-model.trim="maskCreditCard"
                    />
                  </div>
                  <div class="form-group">
                    <label for="card-name">{{
                      $t("card_holder")
                    }}</label>
                    <input
                      type="text"
                      data-selenium-name="card-name"
                      class="form-control"
                      id="card-name"
                      name="card-name"
                      aria-describedby="card-name"
                      :placeholder="$t('name_on_card')"
                      v-model.trim="cardOwnerName"
                    />
                  </div>
                  <div class="row align-content-stretch">
                    <div class="col d-flex flex-column align-content-stretch">
                      <div class="form-group d-flex flex-column flex-grow-1 align-content-stretch">
                        <label for="card-exp-month" class="flex-grow-1">{{
                          $t("expire_month")
                        }}</label>
                        <select
                          id="card-exp-month"
                          data-selenium-name="card-month"
                          :data-selenium-value="cardExpirationMonth"
                          name="card-exp-month"
                          v-model="cardExpirationMonth"
                          class="form-control custom-select"
                          aria-describedby="card-exp-month"
                        >
                          <option selected value="">{{
                            $t("month")
                          }}</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                      </div>
                    </div>
                    <div class="col d-flex flex-column align-content-stretch">
                      <div class="form-group d-flex flex-column flex-grow-1 align-content-stretch">
                        <label for="card-exp-year" class="flex-grow-1">{{
                          $t("expire_year")
                        }}</label>
                        <select
                          id="card-exp-year"
                          data-selenium-name="card-year"
                          :data-selenium-value="cardExpirationYear"
                          name="card-exp-year"
                          v-model="cardExpirationYear"
                          class="form-control custom-select"
                          aria-describedby="card-exp-year"
                        >
                          <option selected value="">{{
                            $t("year")
                          }}</option>
                          <option
                            v-for="year in cardExpirationYears"
                            :key="'card-exp-year' + year"
                            >{{ year }}</option
                          >
                        </select>
                      </div>
                    </div>
                    <div class="col d-flex flex-column align-content-stretch">
                      <div class="form-group d-flex flex-column flex-grow-1 align-content-stretch">
                        <label for="card-cvv" class="flex-grow-1">{{ $t("cvv") }} <button
                          id="what-cvv"
                          type="button"
                          class="btn btn-primary"
                          data-toggle="modal"
                          data-target="#cvv-modal"
                        >
                          ?
                        </button></label>
                        
                        <span
                          id="cvv-icon"
                          class="glyphicons glyphicons-credit-card"
                        ></span>
                        <input
                          type="password"
                          data-selenium-name="card-cvv"
                          maxlength="3"
                          class="form-control card-cvv"
                          id="card-cvv"
                          name="card-cvv"
                          aria-describedby="card-cvv"
                          placeholder="..."
                          v-model.trim="securityCode"
                        />
                      </div>
                    </div>
                  </div>

                  <!-- <div class="form-group">
                    <label for="card-bank">{{
                      $t("issuing_bank")
                    }}</label>
                    <select
                      id="card-bank"
                      data-selenium-name="card-bank"
                      :data-selenium-value="hdCardBank"
                      name="card-bank"
                      v-model="hdCardBank"
                      class="form-control custom-select"
                      aria-describedby="card-bank"
                    >
                      <option selected value="">{{
                        $t("bank_name")
                      }}</option>
                      <option value="AIG CARD  ">AIG CARD </option>
                      <option value="Bangkok Bank">Bangkok Bank</option>
                      <option value="Bank of America, National Association"
                        >Bank of America, National Association</option
                      >
                      <option value="Bank of Asia Public Company Limited"
                        >Bank of Asia Public Company Limited</option
                      >
                      <option value="Bank of Ayudhya">Bank of Ayudhya</option>
                      <option value="Bank of China">Bank of China</option>
                      <option value="BNP Paribas">BNP Paribas</option>
                      <option value="CAPITAL OK">CAPITAL OK</option>
                      <option value="Central Credit Card"
                        >Central The 1 Credit Card</option
                      >
                      <option value="CIMB THAI Bank">CIMB THAI Bank</option>
                      <option value="Citibank">Citibank</option>
                      <option
                        value="Credit Agricole Corporate and Investment Bank"
                        >Credit Agricole Corporate and Investment Bank</option
                      >
                      <option value="Deutsche Bank AG."
                        >Deutsche Bank AG.</option
                      >
                      <option value="GE Capital (Thailand) Limited"
                        >GE Capital (Thailand) Limited</option
                      >
                      <option value="GENERAL CARD SERVICES LTD."
                        >GENERAL CARD SERVICES LTD.</option
                      >
                      <option value="HSBC">HSBC</option>
                      <option value="Indian Overseas Bank"
                        >Indian Overseas Bank</option
                      >
                      <option
                        value="Industrial and Commercial Bank of China (Thai)"
                        >Industrial and Commercial Bank of China (Thai)</option
                      >
                      <option value="JP Morgan Chase Bank"
                        >JP Morgan Chase Bank</option
                      >
                      <option value="Kasikornbank">Kasikornbank</option>
                      <option value="Kiatnakin Bank">Kiatnakin Bank</option>
                      <option value="Krung Thai Bank">Krung Thai Bank</option>
                      <option value="Land and Houses Bank"
                        >Land and Houses Bank</option
                      >
                      <option value="Mega International Commercial Bank"
                        >Mega International Commercial Bank</option
                      >
                      <option value="Mizuho Corporate Bank, Ltd."
                        >Mizuho Corporate Bank, Ltd.</option
                      >
                      <option value="Oversea Chinese Banking Corporation"
                        >Oversea Chinese Banking Corporation</option
                      >
                      <option value="RHB Bank Berhad">RHB Bank Berhad</option>
                      <option value="Simple VISA Card">Simple VISA Card</option>
                      <option value="Standard Chartered Bank (Thai)"
                        >Standard Chartered Bank (Thai)</option
                      >
                      <option value="Sumitomo Mitsui Banking Corporation"
                        >Sumitomo Mitsui Banking Corporation</option
                      >
                      <option value="Thanachart Bank">Thanachart Bank</option>
                      <option value="The Bank of China"
                        >The Bank of China</option
                      >
                      <option value="The Bank of Tokyo-Mitsubishi UFJ"
                        >The Bank of Tokyo-Mitsubishi UFJ</option
                      >
                      <option value="The Government Savings Bank"
                        >The Government Savings Bank</option
                      >
                      <option value="The Royal Bank of Scotland N.V."
                        >The Royal Bank of Scotland N.V.</option
                      >
                      <option value="The Siam Commercial Bank"
                        >The Siam Commercial Bank</option
                      >
                      <option value="The Thai Credit Retail Bank"
                        >The Thai Credit Retail Bank</option
                      >
                      <option value="TISCO Bank">TISCO Bank</option>
                      <option value="TMBThanachart Bank Public Company Limited">TMBThanachart Bank Public Company Limited</option>
                      <option value="TMB Bank Public Company Limited"
                        >TMB Bank Public Company Limited</option
                      >
                      <option value="United Overseas Bank (Thai) Company Ltd."
                        >United Overseas Bank (Thai) Company Ltd.</option
                      >
                      <option value="other">{{
                        $t("bank_other")
                      }}</option>
                    </select>
                  </div>

                  <div
                    id="other-bank"
                    class="form-group"
                    v-show="hdCardBank == 'other'"
                  >
                    <input
                      type="text"
                      class="form-control"
                      data-selenium-name="card-other-bank"
                      v-model.trim="hdCardOtherBank"
                      id="card-other-bank"
                      name="card-other-bank"
                      :placeholder="$t('bank_other')"
                    />
                  </div> -->
                </div>
              </div>
              <div v-if="Global.paymentMethod=='Virtual Account' || Global.opp.paymentMethod == 'Virtual Account'">
                <div class="accordion my-3" id="accordion-ebanking">
                  <div class="row" style="margin-left: 0px; margin-right: 0px;">
                    <div :class="'col-'+countVA +' col-sm-'+countVA + ' col-md-'+countVA + ' col-lg-'+countVA + ' card'" v-for="(data,i) in Global.VirtualAccount" :key="i">
                      <div class="card-body text-center" style="padding:0.5rem">
                        <p class="card-title"><strong>Bank {{data.bank}}</strong> </p>
                      </div>
                      <picture class="text-center" style="padding-bottom:1rem">
                        <source media="(min-width: 520px)" :srcset="'images/'+data.bank +'-logo.svg'">
                        <img :src="'images/'+data.bank +'-logo.svg'" class="img-bank" :alt="'Bank'+data.bank">
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="Global.paymentMethod=='Debit Card' || Global.opp.paymentMethod == 'Debit Card'">
                <div class="accordion my-3" id="accordion-ebanking">
                  <div class="row" style="margin-left: 0px; margin-right: 0px;">
                    <div class='col-12 col-sm-12 col-md-6 col-lg-6 card' >
                      <div class="card-body text-center" style="padding:0.5rem">
                        <p class="card-title"><strong>Bank BRI</strong> </p>
                      </div>
                      <picture class="text-center" style="padding-bottom:1rem">
                        <source media="(min-width: 520px)" srcset="images/BRI-logo.svg">
                        <img src="images/BRI-logo.svg" class="img-bank" alt="Bank">
                      </picture>
                    </div>
                    <div class='col-12 col-sm-12 col-md-6 col-lg-6 card' >
                      <div class="card-body text-center" style="padding:0.5rem">
                        <p class="card-title"><strong>Bank Mandiri</strong> </p>
                      </div>
                      <picture class="text-center" style="padding-bottom:1rem">
                        <source media="(min-width: 520px)" srcset="images/MANDIRI-logo.svg">
                        <img src="images/MANDIRI-logo.svg" class="img-bank" alt="Bank">
                      </picture>
                    </div>

                  </div>
                </div>
              </div> -->

              <div
                class="btn-group-toggle text-left label-checkbox my-3"
                data-toggle="buttons"
                v-if="(Global.tp === 'nb' || Global.tp === 'ren') && (Global.selectedPackages.Cancer || Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP)"
              >
                <label
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: autoRenewFlag }"
                  for="auto-renewal"
                  data-selenium-name="autoRenewFlag"
                  :data-selenium-value="autoRenewFlag ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="auto-renewal"
                    v-model="autoRenewFlag"
                  />
                  {{ $t("automaticRenewal") }}
                </label>
              </div>

              <!-- <div
                class="btn-group-toggle text-left label-checkbox mb-3"
                data-toggle="buttons"
                v-if="Global.tp === 'nb' && (Global.selectedPackages.Cancer || Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP)"
              >
                <label
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: taxDeduction }"
                  for="tax-deduction"
                  data-selenium-name="taxDeduction"
                  :data-selenium-value="taxDeduction ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="tax-deduction"
                    v-model="taxDeduction"
                  />
                  {{ $t("taxDeductionConsent") }}
                </label>
              </div> -->

              <!-- <div
                class="btn-group-toggle text-left label-checkbox"
                data-toggle="buttons"
                v-if="Global.tp === 'nb'"
              >
                <label
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: ePolicyDocument }"
                  for="e-policy-document"
                  data-selenium-name="ePolicyDocument"
                  :data-selenium-value="ePolicyDocument ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="e-policy-document"
                    v-model="ePolicyDocument" @click="epolicyModal()"
                  />
                  {{ $t("ePolicy") }}
                </label>
              </div> -->

              <!-- <div
                class="btn-group-toggle text-left label-checkbox"
                data-toggle="buttons"
                v-if="Global.tp === 'nb' && (Global.selectedPackages.Cancer || Global.selectedPackages.Covid19 || Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP)"
              >
                <label
                  class="btn btn-light btn-check"
                  v-bind:class="{ active: ePolicyDocument }"
                  for="e-policy-document"
                  data-selenium-name="ePolicyDocument"
                  :data-selenium-value="ePolicyDocument ? 'Yes' : 'No'"
                >
                  <input
                    type="checkbox"
                    autocomplete="off"
                    id="e-policy-document"
                    v-model="ePolicyDocument"
                  />
                  {{ $t("ePolicy") }}
                </label>
              </div> -->
              <div class="my-5" v-show="Global.tp =='nb' || Global.tp === 'ren'">
                <button
                  type="button"
                  class="btn btn-lg btn-block btn-link-default buy-now"
                  data-selenium-name="submitCreditCard"
                  :disabled="Global.paymentMethod=='Credit Card'?!enablePayment:false"
                  id="btn-pay-secure"
                  @click="Global.paymentMethod=='Credit Card'?submitCreditCard():Global.paymentMethod=='Virtual Account'?submitVirtualAccount():submitDebitCard();">
                  {{$t("next")}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="padding-top:10vh">&nbsp;</div>
    <div class="modal modal-v1 fade" id="automatic-debit-modal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content white-bg">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              data-selenium-name="epolicy-close"
            >
              X
            </button>
            <h5 class="modal-title mt-4 text-center">
              {{ $t("automatic_debit.title") }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="text-black text-center mt-2">
              {{ $t("automatic_debit.body") }}
            </div>
          </div>
          <div class="modal-footer d-flex flex-column flex-md-row justify-content-center d px-2 px-md-3 pb-2">
            <button type="button" class="btn btn-cancel mb-2 mb-md-0 ml-0 ml-md-n1 mr-0 mr-md-1" styel="color:#000" id="modal-btn-no" @click="automaticDebitNo()" data-selenium-name="epolicy-no">{{ $t("automatic_debit.button_no") }}</button>
            
            <button type="button" class="btn btn-confirm ml-0 ml-md-1 mr-0 mr-md-n1 mb-0" id="modal-btn-yes" @click="automaticDebitYes();" data-selenium-name="epolicy-yes">{{ $t("automatic_debit.button_yes") }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="debit-card-modal" tabindex="-1" >
      <div class="modal-dialog" style="max-width:450px !important;">
        <div class="modal-content white-bg" style="padding: 15px 15px !important">
          <div class="modal-body text-center p-5">
            <img src="images/thankyou-va.png" alt="" style="height: 150px" />
            <div class="text-black text-left mt-4">
              <h4>{{ $t("debit_card_modal.title") }}</h4>
              {{ $t("debit_card_modal.body") }} 
              <vue-countdown v-if="counting" :time="5000" @end="onCountdownEnd" v-slot="{ totalSeconds }">{{ totalSeconds }}</vue-countdown> 
              <span v-if="!counting">0</span>
              {{ $t("debit_card_modal.seconds") }}
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center pb-2">
            <button type="button" class="btn btn-cancel mb-0" id="modal-btn-yes" data-selenium-name="debit-payment-cancel" data-dismiss="modal" @click="this.counting = false" style="width:400px;">
              <strong>{{ $t("debit_card_modal.cancel") }}</strong> 
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal-v1 fade" id="debiterror-modal" tabindex="-1">
      <div class="modal-dialog" style="max-width:400px !important;">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              X
            </button>
            <div class="text-center mt-3">
              <img src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" data-src="images/sorry4commercial.png" class="lazy" style="width:120px;"/>
            </div>
          </div>
          <div class="modal-body">
            <h6 class="text-black">{{ $t("debit_card_error.title") }}</h6>
            <div class="text-black">
            <span>{{ $t("debit_card_error.message") }}
            <a
                href="tel:02150890822"
                role="button"
                style="color: #ff5011;"
                data-selenium-name="footer-roojai-number"
                data-selenium-value="021 5089 0822"
              >
                Contact Center
              </a>
              {{ $t("debit_card_error.message2") }}</span>
            </div>
          </div>
          <div class="modal-footer pb-2 px-2">
            <button
              type="button"
              class="btn btn-confirm w-100"
              data-dismiss="modal"
              @click="submitDebitCard()"
            >
              {{ $t("debit_card_error.button_confirm") }}
            </button>
            <button
              type="button"
              class="btn btn-cancel w-100 my-3"
              data-dismiss="modal"
            >
              {{ $t("debit_card_error.button_cancel") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    

    <div
      id="payment-accept-modal"
      data-selenium-name="payment-accept-modal"
      class="modal fade insure-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body py-5 p-md-5">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              data-selenium-name="payment-accept-modal-close"
              aria-label="Close"
              style="color: rgb(244, 67, 54); opacity: 0.8;"
            >
              <span aria-hidden="true">x</span>
            </button>
            <h2 class="h1section-text2 h4 text-left mb-3">
              {{ $t("dear") }}
              {{ Global.phaccount.firstName }} {{ Global.phaccount.lastName }}
            </h2>

            <div
              id="disclaimer"
              class="scrollbar-default"
              data-selenium-name="payment-accept-modal-content"
            >
              <div class="h6 mb-3">
                {{ $t("roojaiContitions") }}
              </div>
              <div class="mb-3">
                {{ $t("pdpaConsent") }}
              </div>

              <div class="h6 pb-3 pt-4" style="text-indent:0px">
                <button
                  type="button"
                  class="btn-nostyle"
                  @click="disclaimerAccepted = !disclaimerAccepted"
                  data-selenium-name="payment-accept-modal-checked"
                  :data-selenium-value="disclaimerAccepted ? 'Yes' : 'No'"
                >
                  <span
                    class="glyphicons"
                    v-bind:class="[
                      disclaimerAccepted
                        ? 'glyphicons-check'
                        : 'glyphicons-unchecked'
                    ]"
                    style="font-size: 24px;color: #FF5011;margin: -9px 5px 0px 0px;"
                  ></span>
                  {{ $t("iHaveRead") }}
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              data-selenium-name="payment-accept-modal-close-btn"
              class="btn btn-cancel pull-left"
              data-dismiss="modal"
            >
              {{ $t("back") }}
            </button>
            <button v-if="use_va == true"
              type="button"
              data-selenium-name="payment-accept-modal-confirm"
              class="btn btn-confirm pull-right"
              data-dismiss="modal"
              :disabled="!disclaimerAccepted"
              v-on:click="virtualAccount()"
            >
              {{ $t("confirm_pay") }}
            </button>
            <button v-else
              type="button"
              data-selenium-name="payment-accept-modal-confirm"
              class="btn btn-confirm pull-right"
              data-dismiss="modal"
              :disabled="!enablePayment || !disclaimerAccepted"
              v-on:click="confirmCreditCard()"
            >
              {{ $t("confirm_pay") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import axios from "axios";
import accounting from "accounting-js";
import dayjs from "dayjs";
import MasterCard from '../../public/images/mastercard.png'
import Visa from '../../public/images/visa.png'
import useClipboard from 'vue-clipboard3'

export default {
  name: "payment",
  mixins: [Mixin],
  setup() {
    const { toClipboard } = useClipboard()

    const copy = async (bank) => {
      try {
        await toClipboard(bank)
        // this.$bvToast.toast(bank, {
        //   title: title,
        //   autoHideDelay: 5000,
        //   appendToast: false
        // })
        // console.log(bank)
      } catch (e) {
        console.error(e)
      }
    }

    return { copy }
  },

  data() {
    return {
      Global,
      MasterCard : MasterCard,
      Visa : Visa,
      maskCreditCard: "",
      cardOwnerName: "",
      cardNumber: "",
      cardExpirationMonth: "",
      cardExpirationYear: "",
      securityCode: "",
      hdCardBank: "",
      hdCardOtherBank: "",
      cardExpirationYears: [],
      taxDeduction: false,
      autoRenewFlag: false,
      disclaimerAccepted: false,
      // ePolicyDocument: true,
      instalmentNo: 0,
      vaNumber: null,
      bankCode: null,
      disabledToggle: false,
      use_va: false,
      counting: false,
      va_prefix_bca: process.env.VUE_APP_VA_PREFIX_BCA,
      va_prefix_bni: process.env.VUE_APP_VA_PREFIX_BNI,
      va_prefix_mandiri: process.env.VUE_APP_VA_PREFIX_MANDIRI,
      paymentMethod: (Global.opp.paymentMethod == 'Credit Card' ? 'card' : 'Debit Card' ? 'debit' : 'Virtual Account' ? 'va' : '')
    };
  },
  async created() {
    if (Global.quote.productType === Global.enumProductType.PA) {
      document.title = "Personal Accident Insurance";
    }
    if (Global.quote.productType === Global.enumProductType.HCP) {
      document.title = "Hospital Cash Plan Insurance";
    }
    if (["rec"].indexOf(Global.tp) > -1 && Global.encryptedOpportunityNumber) {
      this.loading("show");
      if (Global.sfmcsService.token === "") {
        await this.getSfmcsToken();
        try {
          let endorsementData = await this.getEndorsementData();

          Global.premiumToPay = endorsementData.data.installment_amount;
          Global.quote.opportunityNumber = endorsementData.data.opp_name;
          Global.quote.prefLang = endorsementData.data.preferred_language;
          Global.quote.productType = endorsementData.data.product_type;
          this.instalmentNo = endorsementData.data.installment_number;
        } catch (error) {
          console.error(error);
        }
      }
      this.loading("hide");
    }
    // if(Global.tp === 'ren'){
    //   this.createVA()
    // }
    if (Global.quote.taxDeduction === "Yes") {
      this.taxDeduction = true;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    $(window).off("scroll");
    $("nav").removeClass("fixed-menu");
    $("body").removeClass("scroll-down");

    var now = dayjs(Global.serverTime, "YYYY-MM-DD HH:mm:ss");
    for (var year = now.year(); year < now.year() + 10; year++) {
      this.cardExpirationYears.push(year.toString());
    }
    if(Global.paymentFrequency == '12 months, no deposit' && Global.paymentMethod == 'Credit Card'){
        $("#automatic-debit-modal").modal("show");
    }
    this.getVirtualAccount();
    if(Global.tp === 'ren'){
      this.createVA()
    }
  },
  methods: {
    async createVA(){
      var vm = this;
      vm.loading("show");
      let quotation = {
        "checkPayment": true,
        "opportunityNumber": Global.quote.opportunityNumber
      }
      var response = await axios.post(
        vm.urlDecommission.urlPaymentVA,
        quotation,
        {
          headers: { Authorization: "Bearer " + vm.urlDecommission.token },
        }
      );
      vm.loading("hide", function () {
          if (response.status != 200) {
            vm.loading("hide", function () {
              vm.errorModal(vm.$t("vaError"));
              vm.$router.push(Global.productConfig.route.basicinfo);
            });
          }
        });
    },
    choose_bank: function(bank) {
      Global.VAinfo.bank = bank
    },
    automatic_debit:function(){
      if(Global.paymentFrequency == '12 months, no deposit' && (Global.paymentMethod == 'Credit Card' || Global.paymentMethod == 'Debit Card')){
        $("#automatic-debit-modal").modal("show");
      }
    },
    automaticDebitYes: function() {
      Global.paymentFrequency = '12 months, no deposit'
      $("#automatic-debit-modal").modal("hide");
    },
    automaticDebitNo: function() {
      Global.paymentFrequency = 'Yearly' 
      $("#automatic-debit-modal").modal("hide");
    },
    checkAvailableInstallmentVA: function() {
      if (!this.AvailableInstallment.VA) {
        Global.paymentFrequency = 'Yearly' 
      }
    },
    submitCreditCard: function () {
      this.confirmCreditCard();
      // this.use_va = false;
      // if (Global.tp === "nb") {
      //   $("#payment-accept-modal").modal("show");
      // } else {
      //   this.confirmCreditCard();
      //   // this.confirmCreditCardTest();
      // }
    },
    // confirmCreditCardTest: function () {
    //   var vm = this;
    //     vm.saveQuote();
    // },
    confirmCreditCard: function () {
      var vm = this;

      vm.loading("show");
      var merchantTotalAmount = this.amount;
      merchantTotalAmount = parseFloat(merchantTotalAmount);
      Xendit.setPublishableKey(Global.xenditPublicKey);
      Xendit.card.createToken({
        amount: parseInt(merchantTotalAmount.toString().replace(".","")),
        card_number: vm.cardNumber,
        card_exp_month: vm.cardExpirationMonth,
        card_exp_year: vm.cardExpirationYear,
        card_cvn: vm.securityCode,
        currency: "",
        is_multiple_use: true,
        should_authenticate: true
    }, async function (err, creditCardToken){
      if (err) {
        vm.loading("hide", function () {
            vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
        });
        console.log(err)
        return;
      }

      if (creditCardToken.status === 'VERIFIED') {
        // Get the token ID:
        var token = creditCardToken.id;
        var authenticationId = creditCardToken.authentication_id;
        var paymentResponse = null;
        try {
          paymentResponse = await axios.post(vm.urlDecommission.urlPaymentCC,//await axios.post(Global.restServer.url + "/payments/creditCardPaymentByXendit",
                {
                  amount: merchantTotalAmount,
                  tokenId: token,
                  authenticationId: authenticationId,
                  opportunityNumber: Global.quote.opportunityNumber,
                  issuingBank: creditCardToken.card_info.bank,
                  paymentFrequency: Global.paymentFrequency,
                  customerName: vm.cardOwnerName,
                  autoRenewFlag: vm.autoRenewFlag === true ? "Yes" : "No",
                  isPmntInitiatedBySF: Global.payment.isPmntInitiatedBySF,
                  agentId: Global.payment.agentId,
                  card: {
                    number: creditCardToken.masked_card_number,
                    brand: creditCardToken.card_info.brand,
                    expMonth: vm.cardExpirationMonth,
                    expYear: vm.cardExpirationYear
                  },
                  ...(Global.isTestCaseCreditCard === true && {"_case": Global.testCaseCreditCard}),
                },
                {
                  headers: {
                    Authorization: "Bearer " + vm.urlDecommission.token,
                  },
                });
                Global.paymentID = paymentResponse.data.paymentID;
              if (paymentResponse.data.paymentStatus === "00") {
                Global.paymentCompleted = true;
                Global.quote.Start_date = vm.date(Global.serverTime).format("YYYY-MM-DD")
                Global.quote.End_date = vm.date(Global.quote.Start_date).add(1, 'year').format("YYYY-MM-DD")
                Global.quote.onlineStage = "success";
              }else if(paymentResponse.data.paymentStatus === "99"){
                vm.loading("hide", function () {
                  vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
                });
              } else {
                Global.paymentCompleted = false;
                Global.quote.onlineStage = "failure";
              }
        } catch (error) {
          console.error(error);
          Global.paymentID = error.response?.data?.paymentID;
          Global.paymentCompleted = false;
          Global.quote.onlineStage = "failure";
        }

        // Global.quote.ePolicyDocument = vm.ePolicyDocument === true ? "Yes" : "No";
        Global.quote.taxDeduction = vm.taxDeduction === true ? "Yes" : "No";

        if (
          Global.paymentCompleted === true 
          // &&
          // vm.checkAddressAndBeneficiary()
        ) {
          Global.quote.isActivatePolicy = true;
          var options = {
            callMeBackRequest : 'No'
          }
        }
        vm.saveQuote(options);
        if (Global.paymentCompleted === true) {
          vm.loading("hide");
          vm.$router.push(
            Global.quote.isActivatePolicy
              ? Global.productConfig.route.review
              : Global.productConfig.route.policydetails2
          );
        } else {
          vm.loading("hide", function () {
            vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
          });
        }
      } else if (creditCardToken.status === 'IN_REVIEW') {
        vm.loading("hide", function () {
          window.open(creditCardToken.payer_authentication_url, 'sample-inline-frame');
        });
      } else {
        vm.loading("hide", function () {
          vm.errorModal(vm.$t("creditCardError"),'title',vm.$t("creditCardErrorTitle"),vm.$t("creditCardErrorContact"));
        });
      }
    })
    },
    creditcardFormat: function (cc, delimeter) {
      if (!cc) {
        return cc;
      }

      delimeter = delimeter || " ";
      const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
      const onlyNumbers = cc ? cc.replace(/[^\d]/g, "") : "xxxx";

      return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter((group) => !!group).join(delimeter)
      );
    },
    checkAddressAndBeneficiary: function () {
      return (
        Global.phaccount.postalCode !== "" &&
        Global.phaccount.province !== "" &&
        Global.phaccount.provinceCode !== "" &&
        Global.phaccount.district !== "" &&
        Global.phaccount.districtCode !== "" &&
        Global.phaccount.subDistrict !== "" &&
        Global.phaccount.subDistrictCode !== "" &&
        Global.phaccount.houseNumber !== ""
      );
    },
    getEndorsementData: function () {
      return axios.get(
        Global.sfmcsService.url +
          "/endorsement/" +
          encodeURIComponent(Global.encryptedOpportunityNumber),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Global.sfmcsService.token,
          },
        }
      );
    },
    makeAPayment: async function (tokenId) {
      var object = {
          source: {
            amount: Math.round(Global.premiumToPay * 100),
            currency: "THB",
          },
          charge: {
            metadata: {
              reference1: "01",
              refNo: Global.serverTime.replace(/\D/g, ""),
              isPmntInitiatedBySF: Global.payment.isPmntInitiatedBySF,
              agentId: Global.payment.agentId,
              tp: Global.tp,
            },
            description: Global.quote.opportunityNumber,
            capture: true,
          },
          token: tokenId,
        },
        charge = await axios.post(
          Global.roojaiService.url + "/payments/charge",
          object,
          {
            headers: { Authorization: "Bearer " + Global.roojaiService.token },
          }
        );

        if(charge.data.status === "successful" && Global.tp === "rec" && ["Personal Accident", "Health"].indexOf(Global.quote.productType) > -1){
          this.loading("hide");
          this.$router.replace("review");
        }
    },
    submitVirtualAccount: function () {
      this.virtualAccount();
      // this.use_va = true;
      // if (Global.tp === "nb") {
      //   $("#payment-accept-modal").modal("show");
      // } else {
      //   this.virtualAccount();
      // }
    },
    virtualAccount: async function() {
      let vm = this;
      // vm.disabledToggle = true;
      vm.loading("show");
      var quotation = this.getProtectionQuotation();
      try {
        quotation["item"]["phaccount"]["email"];
        quotation["item"]["phaccount"]["phoneNumber"];
        quotation["item"]["phaccount"]["countryOfIssuance"];
        quotation["item"]["phaccount"]["thaiIdNumber"];
        quotation["item"]["phaccount"]["passPortNumber"];
        quotation["item"]["phaccount"]["sfAccountId"] = Global.sfAccountId;
        quotation.item.phaccount.accountExternalId = Global.accountExternalId;
        quotation.item.payment.autoRenewFlag = vm.autoRenewFlag === true ? "Yes" : "No"
        // Global.payment.autoRenewFlag = vm.autoRenewFlag === true ? "Yes" : "No"
        // Global.quote.FlagAutoRenewal = vm.autoRenewFlag
        // console.log("quotation ",quotation)
        // vm.saveQuote();
        var response = await axios.post(
            vm.urlDecommission.urlPaymentVA,
            quotation,
            {
              headers: { Authorization: "Bearer " + vm.urlDecommission.token },
            }
          );
        vm.vaNumber = null;
        // Global.quote.ePolicyDocument = vm.ePolicyDocument === true ? "Yes" : "No";
        Global.quote.taxDeduction = vm.taxDeduction === true ? "Yes" : "No";
        Global.paymentID = response.data.paymentID;

        vm.loading("hide", function () {
          if (response.data.success == true) {
            Global.quote.onlineStage = "Proceed to payment";
            Global.VAinfo.accountNumber = response.data.vaDetails[0].accountNumber
            Global.VAinfo.totalPayment = response.data.vaDetails[0].price
            Global.VAinfo.frequency = ''
            Global.VAinfo.bank = response.data.vaDetails[0].bank
            Global.responseCreateVa = response.data.vaDetails
            let mappingResultVA = []
            response.data.vaDetails.forEach((element) => 
              mappingResultVA.push({[element.bank]:[element]})
            );
            Global.resultCreateVa = mappingResultVA;
            vm.loading("hide");
            
            vm.$router.push(Global.productConfig.route.waitingpayment);
          }else {
            vm.loading("hide", function () {
              vm.errorModal(vm.$t("vaError"));
            });
          }
        });
      } catch (error) {
        console.error(error);
        vm.loading("hide", function () {
          vm.errorModal(vm.$t("vaError"));
        });
      }
    },
    getProtectionQuotation: function(){
      // Global.quote.ePolicyDocument = this.ePolicyDocument === true ? "Yes" : "No";
      Global.quote.taxDeduction = this.taxDeduction === true ? "Yes" : "No";
      var quotation = this.getQuotation();
      delete quotation["productType"];

      quotation.item.quote.isActivatePolicy = false;
      quotation.item.payment = {
        isPmntInitiatedBySF: Global.payment.isPmntInitiatedBySF,
        agentId: Global.payment.agentId,
        deferredPaymentFlag: false
      };

      quotation.item.payments = [
        {
          Payment_Fee__c: "0",
          Payment_date__c: "",
          Bank__c: "Xendit",
          Type__c: "Receivable",
          WHT_certificate_number__c: "",
          Installment_number__c: "1",
          Credit_Amount__c: this.amount,
          Payment_frequency__c: Global.paymentFrequency,
          Status__c: "",
          Payment_method__c: "",
          Charge_Id__c: ""
        }
      ];
      return quotation;
    },
    submitDebitCard: function () {
      this.debitCard();
      // this.use_va = true;
      // if (Global.tp === "nb") {
      //   $("#payment-accept-modal").modal("show");
      // } else {
      //   this.virtualAccount();
      // }
    },
    debitCard: async function() {
      let vm = this;
      // vm.disabledToggle = true;
      vm.loading("show");
      // if (Global.debitcardInfo.response == null) {
        var quotation = this.getProtectionQuotationDebit();
        try {
          delete quotation["item"]["phaccount"]["email"];
          delete quotation["item"]["phaccount"]["phoneNumber"];
          delete quotation["item"]["phaccount"]["countryOfIssuance"];
          delete quotation["item"]["phaccount"]["thaiIdNumber"];
          delete quotation["item"]["phaccount"]["passPortNumber"];
          quotation.transactionType = '01';
          quotation.phoneNumber = Global.phaccount.phoneNumber;
          quotation.item.phaccount.accountExternalId = Global.accountExternalId;
          // quotation.item.phaccount.occupation = Global.phaccount.occupation?.apiName ? Global.phaccount.occupation?.apiName : "",
          quotation.item.payment.autoRenewFlag = vm.autoRenewFlag === true ? "Yes" : "No"
          // Global.payment.autoRenewFlag = vm.autoRenewFlag === true ? "Yes" : "No"
          // Global.quote.FlagAutoRenewal = vm.autoRenewFlag
          // console.log("quotation ",quotation)
          // vm.saveQuote();
          var response = await axios.post(
              Global.odataService.url + "/payment/ayc/createBinding",
              quotation,
              {
                headers: { Authorization: "Bearer " + Global.odataService.token },
              }
            );
          vm.vaNumber = null;
          // Global.quote.ePolicyDocument = vm.ePolicyDocument === true ? "Yes" : "No";
          Global.quote.taxDeduction = vm.taxDeduction === true ? "Yes" : "No";
          Global.paymentID = response.data.paymentID;

          vm.loading("hide", function () {
            if (response.data.success === true) {
              Global.quote.onlineStage = "Proceed to payment";
              Global.debitcardInfo.response = response.data.redirect_url
              vm.loading("hide");
              vm.counting = true;
              $("#debit-card-modal").modal("show");
              
              // vm.$router.push(Global.productConfig.route.debitcard);
            }else {
              vm.loading("hide", function () {
                vm.errorModal(vm.$t("debitCardError"),"debit card");
              });
            }
          });
        } catch (error) {
          console.error(error);
          vm.loading("hide", function () {
            vm.errorModal(vm.$t("debitCardError"),"debit card");
          });
        }
      // }else{
      //   vm.loading("hide");
      //   this.counting = true,
      //   Global.quote.onlineStage = "Proceed to payment";
      //   $("#debit-card-modal").modal("show");
      // }
      
    },
    onCountdownEnd: function () {
      this.counting = false;
      $("#debit-card-modal").modal("hide");
      if (Global.debitcardInfo.response != null) {
        this.redirect();
        // this.$router.push(Global.productConfig.route.debitcard);
      }
      
    },
    redirect() {
      window.location.assign(Global.debitcardInfo.response);
      // document.location.href = Global.debitcardInfo.response;
    },
    showmodaldebitcard: function () {
      $("#debit-card-modal").modal("show");
    },
    getProtectionQuotationDebit: function(){
      // Global.quote.ePolicyDocument = this.ePolicyDocument === true ? "Yes" : "No";
      Global.quote.taxDeduction = this.taxDeduction === true ? "Yes" : "No";
      var quotation = this.getQuotation();
      delete quotation["productType"];

      quotation.item.quote.isActivatePolicy = false;
      quotation.item.payment = {
        isPmntInitiatedBySF: Global.payment.isPmntInitiatedBySF,
        agentId: Global.payment.agentId,
        deferredPaymentFlag: false
      };

      quotation.item.payments = [
        {
          Payment_Fee__c: "0",
          Payment_date__c: "",
          Bank__c: "Ayoconnect",
          Type__c: "Receivable",
          WHT_certificate_number__c: "",
          Installment_number__c: "1",
          Credit_Amount__c: this.amount,
          Payment_frequency__c: Global.paymentFrequency,
          Status__c: "",
          Payment_method__c: "",
          Charge_Id__c: ""
        }
      ];
      return quotation;
    }
  },
  watch: {
    maskCreditCard: function (value) {
      value = value.replace(/[^0-9]/g, "");
      this.maskCreditCard = this.creditcardFormat(value);

      if (value.length == 16) {
        this.cardNumber = value.replace(/\s+/g, "");
      }
    },
    hdCardBank: function (value) {
      if (value != "other") {
        this.hdCardOtherBank = "";
      }
    },
  },
  computed: {
    enablePayment: function () {
      return (
        this.cardNumber.length == 16 &&
        this.cardOwnerName &&
        this.securityCode.length == 3 
        // &&
        // (this.hdCardBank ||
        //   (this.hdCardBank == "other" && this.hdCardOtherBank))
      );
    },
    countVA: function () {
      return (
        Global.VirtualAccount.length==1?12:Global.VirtualAccount.length==2?6:Global.VirtualAccount.length>=3?4:4
      );
    },
    paymentTitle: function () {
      return Global?.packages?.twelveMonthsNoDepositPaymentLoadingFactor
        ?.value > 0
        ? this.$t("choose_payment_method")
        : this.$t("payment");
    },
    amount: function(){
      var amount = "0";
      if (Global.paymentFrequency === "Yearly") {
        amount = (Global.tp == "ren" ?  Global.renewalPrices.totalTransactionPremium :  accounting.toFixed(this.totalPremium, 0)) ;
      } else if (Global.paymentFrequency === "12 months, no deposit") {
        if (Global.quote.productType == Global.enumProductType.PA || Global.quote.productType == Global.enumProductType.HCP || Global.quote.productType == Global.enumProductType.Health) {
          amount = Global.tp == "ren" ?  Global.renewalPrices.twelveMonthlyInstallmentAmnt : accounting.toFixed(this.twelveMonthsNoDepositPremium.monthlyInstallment, 0);
        } else {
          amount = accounting.toFixed(this.firstInstallmentNoDepositPremium, 0);
        }
      }
      return amount;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "errorTBAtitle" : "Oops, Something went wrong",
    "errorTBAtext": "It looks like our server is playing up right now. Try again in a bit.",
    "payment": "Your payment",
    "choose_payment_method": "Choose your payment method",
    "cc": "Credit Card",
    "debit_card": "Debit Card",
    "full_mayment": "Full payment",
    "installment": "Instalment",
    "total_price": "Total Price",
    "select_bank": "Select a bank",
    "continue": "Continue",
    "your_vanumber": "Virtual Account Number",
    "copy_clipboard": "Copy",
    "first_installment": "Initial deposit",
    "11_installment": "Instalment",
    "11_months": "x 11 months",
    "total_12_months": "Total price",
    "card_info": "Card informations",
    "credit_card": "Credit / Debit card number",
    "card_holder": "Card holder name",
    "name_on_card": "Name on card",
    "expire_month": "Expire month",
    "expire_year": "Expire year",
    "cvv": "CVV",
    "month": "Month",
    "year": "Year",
    "currency" :"IDR ",
    "issuing_bank": "Card issuing bank",
    "bank_name": "BANK NAME",
    "bank_other": "Other bank",
    "confirm_pay": "Confirm and Pay",
    "creditCardErrorTitle":"Oops, The Payment Didn't Go Through",
    "creditCardError":"Please make sure your credit card is active and has sufficient balance. You can also try with a different credit card or a different method of payment. If you need assistance, please ",
    "creditCardErrorContact":"Contact Us",
    "vaError":"Oops, an error occurred, and we cannot proceed virtual account payment. Please try again later",
    "debitCardError":"Oops, an error occurred, and we cannot proceed debit card payment. Please try again later",
    "months": "Months",
    "total": "Total",
    "taxDeductionConsent": "To exercise the right to apply for income tax deduction under the tax legislation, I consent to the insurer to disclose the insurance premium data to the Revenue Department following the prescribed conditions and methods.",
    "automaticRenewal": "Automatically renew my policy",
    "productCancer": "Cancer",
    "ePolicyTitle": "E-policy",
    "ePolicy": "Yes, I want to save the environment by receiving my e-policy",
    "ePolicyDesc": "Are you sure you want to receive a hardcopy? A surcharge might apply for the hardcopy. E-policy is much faster, safer, easy to access anytime anywhere and environmentally friendly.",
    "ePolicyYes": "Yes, I want e-policy and save the environment!",
    "ePolicyNo": "No, I need the hardcopy",
    "dear": "Hi",
    "roojaiContitions": "You are one step closer to having your protection! Please confirm your acceptance of the following points to continue.",
    "pdpaConsent": "I agree that the insurer can collect, use and disclose your personal information or those whom you represent to buy this product, to insurance agents, insurance brokers, other relevant third party, such as third-party service providers of the insurance company, supervisory bodies such as the Indonesian Financial Services Authority (Otoritas Jasa Keuangan/OJK), and other relevant government institution. This shall include the health information of the relevant persons of this policy. The data can be used for underwriting and claims purposes or any other insurance arrangements and regulations of the industry.",
    "iHaveRead": "I have read and agree to the above statement",
    "instalmentNo": "Instalment no. {instalmentNo}",
    "automatic_debit" : {
      "title" : "Automatic Debit",
      "body" : "I agree for automatic debiting of my credit/debit card listed above.",
      "button_no" : "I disagree",
      "button_yes" : "I agree"
    },
    "debit_card_modal" : {
      "title" : "Hang Tight!",
      "body" : "You’re being redirected to another page. It may take up to ",
      "seconds" : " seconds",
      "cancel" : "Cancel Payment"
    },
    "debit_card_error" : {
      "title" : "Seems like we’re facing an issue",
      "message" : "We couldn’t get you to your destination page. Try again in a few minutes or contact our ",
      "message2" : "",
      "button_cancel" : "Change Payment Method",
      "button_confirm" : "Try Again",
    }
  },
  "id": {
    "errorTBAtitle" : "Maaf, Terjadi Kesalahan",
    "errorTBAtext": "Sepertinya server kami sedang terkendala sekarang. Coba lagi beberapa saat.",
    "payment": "Pembayaran kamu",
    "choose_payment_method": "Pilih metode pembayaran",
    "cc": "Kartu Kredit",
    "debit_card": "Kartu Debit",
    "full_mayment": "Pembayaran penuh",
    "installment": "Cicilan",
    "total_price": "Harga total",
    "select_bank": "Pilih bank",
    "continue": "Lanjut",
    "your_vanumber": "Nomor Virtual Account",
    "copy_clipboard": "Salin",
    "first_installment": "Cicilan awal",
    "11_installment": "Cicilan",
    "11_months": "x 11 bulan",
    "total_12_months": "Harga total",
    "card_info": "Informasi kartu",
    "credit_card": "Nomor kartu kredit/debit",
    "card_holder": "Nama pemegang kartu",
    "name_on_card": "Nama pada kartu",
    "expire_month": "Bulan kadaluwarsa",
    "expire_year": "Tahun kadaluwarsa",
    "cvv": "CVV",
    "month": "Bulan",
    "year": "Tahun",
    "currency" :"Rp",
    "issuing_bank": "Bank penerbit kartu",
    "bank_name": "NAMA BANK",
    "bank_other": "Bank lain",
    "confirm_pay": "Konfirmasi dan bayar",
    "automatic_debit" : {
      "title" : "Pendebetan Otomatis",
      "body" : "Saya setuju untuk pendebetan otomatis kartu kredit/debit yang tertera di atas.",
      "button_no" : "Saya tidak setuju",
      "button_yes" : "Saya setuju"
    },
    "debit_card_modal" : {
      "title" : "Mohon Tunggu",
      "body" : "Kamu akan diarahkan ke halaman baru dalam waktu  ",
      "seconds" : " detik",
      "cancel" : "Batalkan Pembayaran"
    },
    "debit_card_error" : {
      "title" : "Halaman yang kamu tuju bermasalah",
      "message" : "Kami tidak dapat menghubungkanmu ke halaman yang dituju. Silakan coba lagi dalam beberapa menit atau hubungi ",
      "message2" : "kami.",
      "button_cancel" : "Ubah Metode Pembayaran",
      "button_confirm" : "Coba Lagi",
    },
    "creditCardErrorTitle":"Ups, Pembayaranmu Belum Berhasil",
    "creditCardError":"Pastikan kartu kredit kamu masih aktif dan limit kartu masih cukup. Kamu juga bisa mencoba pembayaran dengan kartu kredit lain atau gunakan metode pembayaran lainnya. Jika kamu butuh bantuan silakan ",
    "creditCardErrorContact":"Hubungi Kami",
    "vaError":"Ups, terjadi kesalahan dan kami tidak dapat memproses pembayaran virtual account. Silakan coba lagi nanti",
    "debitCardError":"Ups, terjadi kesalahan dan kami tidak dapat memproses pembayaran debit card. Silakan coba lagi nanti",
    "months": "Bulan",
    "total": "Total",
    "taxDeductionConsent": "Untuk menggunakan hak untuk mengajukan pengurangan pajak penghasilan berdasarkan undang-undang pajak, saya menyetujui perusahaan asuransi untuk mengungkapkan data premi asuransi kepada Departemen Pendapatan dengan mengikuti kondisi dan metode yang ditentukan.",
    "automaticRenewal": "Perpanjang polis secara otomatis",
    "productCancer": "Kanker",
    "ePolicyTitle": "E-polis",
    "ePolicy": "Saya mau menyelamatkan bumi dengan menerima polis digital (e-policy)",
    "ePolicyDesc": "Yakin mau menerima polis cetak? Kamu bisa dikenakan biaya jika memilih polis cetak. Dengan memilih polis digital, kamu bisa akses polis kapan saja, dimana saja selama 24/7.",
    "ePolicyYes": "Ya, saya mau e-polis dan selamatkan bumi!",
    "ePolicyNo": "Tidak, saya perlu polis cetak",
    "dear": "Hai",
    "roojaiContitions": "Kamu sudah satu langkah lebih dekat untuk mendapatkan proteksi dari Roojai! Silahkan konfirmasi pernyataan di bawah, dan kami akan memproses asuransi kamu.",
    "pdpaConsent": "Saya setuju bahwa perusahaan asuransi dapat mengumpulkan, menggunakan, dan mengungkapkan informasi pribadi kamu atau yang kamu wakili untuk membeli produk ini, kepada agen asuransi, pialang asuransi, pihak ketiga lain yang relevan, seperti penyedia jasa pihak ketiga dari perusahaan asuransi, badan pengawas seperti Otoritas Jasa Keuangan (OJK), dan lembaga pemerintah lainnya yang terkait. Hal ini mencakup rekam medis dari orang-orang yang relevan dari kebijakan ini. Data tersebut dapat digunakan untuk tujuan penjaminan dan klaim atau pengaturan dan peraturan asuransi lainnya.",
    "iHaveRead": "Saya sudah membaca dan menyetujui pernyataan diatas",
    "instalmentNo": "Nomor cicilan {instalmentNo}",
  },
}
</i18n>
