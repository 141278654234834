<style>
  .topper-area {
    padding: 4px auto;
    gap: 8px;
    color: #C5D9EB;
    font-family: Public Sans;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    background: #2A5C88;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
  }
  .topper-area::-webkit-scrollbar {
    display: none;
  }
  .topper-area .part{
    display: flex;
    max-width: 130px;
    gap: 5px;
    padding: 4px;
  }
  .unselected {
    color: #557EA3;
  }
  .icon-number {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #557EA3;
    color: #9FB9D1;
    line-height: 18px;
    font-size: 12px;
    font-family: Noto Sans;
    overflow: hidden;
    text-align: center;
  }
  .spacer {
    width: 24px;
    height: 0;
    border: 1px solid #557EA3;
    border-radius: 5px;
  }
  .top-area {
    background: linear-gradient(180deg, #2A5C88 0%, #003B70 100%);
    border-radius: 0 0 0 84px;
    padding: 16px 24px;
    gap: 8px;
    font-family: Public Sans;
    font-size: 54px;
    line-height: 60px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .content-area {
    display: flex;
    margin: 16px;
    align-items: center;
  }
  .content-box {
    background: #EBEBEB;
    border: 2px solid #f5f5f5;
    border-radius: 16px 32px;
    gap: 12px;
    padding: 16px;
    width: 328px;
    margin: auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-family: Noto Sans;
    text-align: center;
    color: #393939;
    display: flex;
    flex-direction: column;
  }
  .bill-container {
    background: #fff;
    padding: 0 0 16px 0;
    gap: 12px;
    border-radius: 8px 24px;
    overflow: hidden;
  }
  .bill-header {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    font-family: Public Sans;
    line-height: 22px;
    padding: 16px 48px;
    gap: 8px;
    display: flex;
    background: linear-gradient(180deg, #2A5C88 0%, #003B70 100%);
  }
  .bill-content {
    padding: 16px 16px 0 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
  .bill-row{
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #CCC;
    gap: 5px;
  }
  .bill-amount {
    font-family: Public Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #003B70;
  }
  .bill-label {
    font-family: Public Sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6F6F6F;
    text-align: left;
  }
  .bill-value {
    font-weight: 700;
    font-size: 10px;
    color: #161616;
    text-align: right;
  }
  .copy-btn{
    display: flex;
    color: #FF5011;
    font-size: 12px;
    font-family: Public Sans;
    line-height: 16px;
    font-weight: 700;
    float: right;
    gap: 4px;
    border-bottom: 1px dashed #FF5011;
    cursor: pointer;
  }
  .text-center{
    text-align: center ;
  }
  .bill-main-btn {
    background: linear-gradient(180deg, #FF6D39 0%, #FF5011 100%);
    display: flex;
    padding: 12px;
    border-radius: 8px;
    color: #fff !important;
    font-size: 16px;
    font-family: Public Sans;
    line-height: 22px;
    font-weight: 700;
    justify-content: center;
    cursor: pointer;
  }
  .bill-main-btn:hover {
    background: linear-gradient(180deg, #FF5011 0%, #D4430E 100%);
    text-decoration: none;
  }
  .bill-main-btn:active {
    background: linear-gradient(180deg, #D4430E 0%, #AA350B 100%);
  }
  .bill-secondary-btn {
    background: #FFECE5;
    display: flex;
    padding: 6px 8px;
    border-radius: 8px;
    color: #FF5011 !important;
    font-size: 12px;
    font-family: Public Sans;
    line-height: 16px;
    font-weight: 700;
    justify-content: center;
    border: .5px solid #FF5011;
    cursor: pointer;
    flex-grow: 1;
  }
  .bill-secondary-btn:hover {
    background: #FFD2C2;
    text-decoration: none;
  }
  .bill-secondary-btn:active {
    background: #FFBBA3;
  }
  .btn-row {
    display: flex;
    gap: 8px; 
  }
  @media (max-width: 640px) {
    .top-area{
      font-size: 24px;
      line-height: 28px;
      text-align: left;
      border-radius: 0 0 0 32px;
    }
  }
  .call-roojai {
    padding: 0px 16px 16px 16px;
  }

  .call-roojai .text-muted {
      display: flex;
      padding-bottom: 16px;
      max-width: 245px;
      color: #393939;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
  }

  .call-roojai .text-muted .glyphicons {
      margin-right: 5px;
  }
  .item-center{
    color: #FF5011;
    font-size: 12px;
    font-family: Public Sans;
    line-height: 16px;
    font-weight: 700;
    gap: 4px;
    border-bottom: 1px dashed #FF5011;
    cursor: pointer;
    text-align: center;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .no-border{
    border: 0 !important;
    gap: 5px;
  }
  .unpaid-btn-container{
    display: flex;
    gap: 8px;
  }
  .close-btn{
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 8px;
    background: linear-gradient(180deg, #FF6D39 0%, #FF5011 100%);
    color: #FFF;
    text-align: center;
    font-family: "Public Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.24px;
    cursor: pointer;
  }
  .close-btn:hover {
      background: linear-gradient(180deg, #FF5011 0%, #D4430E 100%);
  }
  .close-btn:active {
      background: linear-gradient(180deg, #D4430E 0%, #AA350B 100%);
  }
</style>

<template>
  <div class="">
    <div class="topper-area">
      <div class="part">
        <div class="icon-number">1</div>
        <div class="flex-grow-1">{{ $t("billMonth", {month: Global.quote.prefLang === 'id' ? currentDueMonth.id : currentDueMonth.en}) }}</div>
      </div>
      <!-- <div class="spacer" v-if="nextDueMonth"></div>
      <div class="part" v-if="nextDueMonth">
        <div class="icon-number">2</div>
        <div class="flex-grow-1 unselected">{{ $t("billMonth", {month: Global.quote.prefLang === 'id' ? nextDueMonth.id : nextDueMonth.en}) }}</div>
      </div> -->
      <div class="spacer"></div>
      <div class="part">
        <div class="icon-number">2</div>
        <div class="flex-grow-1 unselected">{{ $t("billRenewal") }}</div>  
      </div>
    </div>
    <div class="top-area">{{ $t("unpaidTitle",{month: nextDueMonth ? (Global.quote.prefLang === 'id' ? currentDueMonth.id + ' & ' + nextDueMonth.id : currentDueMonth.en + ' & ' + nextDueMonth.en) : (Global.quote.prefLang === 'id' ? currentDueMonth.id : currentDueMonth.en)}) }}</div>
    <div class="content-area">
      <div class="content-box">
        <div v-if="paymentMethod == 'Credit Card'">{{ $t("unpaidDescCC") }}</div>
        <div v-else>{{ $t("unpaidDesc") }}</div>
        <div class="bill-container">
          <div class="bill-header">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.50008 4.16667H5.83341C4.91294 4.16667 4.16675 4.91286 4.16675 5.83333V15.8333C4.16675 16.7538 4.91294 17.5 5.83341 17.5H14.1667C15.0872 17.5 15.8334 16.7538 15.8334 15.8333V5.83333C15.8334 4.91286 15.0872 4.16667 14.1667 4.16667H12.5001M7.50008 4.16667C7.50008 5.08714 8.24627 5.83333 9.16675 5.83333H10.8334C11.7539 5.83333 12.5001 5.08714 12.5001 4.16667M7.50008 4.16667C7.50008 3.24619 8.24627 2.5 9.16675 2.5H10.8334C11.7539 2.5 12.5001 3.24619 12.5001 4.16667M10.0001 10H12.5001M10.0001 13.3333H12.5001M7.50008 10H7.50841M7.50008 13.3333H7.50841" stroke="white" stroke-width="1.4" stroke-linecap="round"/>
            </svg>
            {{ $t("billInfo") }}
          </div>
          <div class="bill-content">
            <div class="bill-label text-center">{{ $t("totalAmount") }}</div>
            <div class="bill-amount">{{ $t("currency") }}{{numberFormat(parseInt(amount))}},-</div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("policyNumber") }}</div>
              <div class="bill-label bill-value">{{opportunity}}</div>
            </div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("insuranceProduct") }}</div>
              <div class="bill-label bill-value">{{productType}}</div>
            </div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("insuredName") }}</div>
              <div class="bill-label bill-value">{{name}}</div>
            </div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("policyExpireDate") }}</div>
              <div class="bill-label bill-value">{{contractEndFormat}}</div>
            </div>
            <div class="bill-row" v-if="paymentMethod == 'Credit Card'">
              <div class="bill-label">{{ $t("bankName") }}</div>
              <div class="bill-label bill-value">{{cardIssueBank}}</div>
            </div>
            <div class="bill-row" v-if="paymentMethod == 'Virtual Account' && VAinfo.bank !== ''">
              <div class="bill-label">{{ $t("bankName") }}</div>
              <div class="bill-label bill-value">{{VAinfo.bank}}</div>
            </div>
            <div class="bill-row" v-if="paymentMethod == 'Credit Card'">
              <div class="bill-label">{{ $t("ccNumber") }}</div>
              <div class="bill-label bill-value">{{cardNumber}}</div>
            </div>
            <div class="bill-row"  v-if="paymentMethod == 'Virtual Account' && VAinfo.accountNumber !== ''">
              <div class="bill-label">{{ $t("vaNumber") }}</div>
              <div class="bill-label bill-value">
                {{VAinfo.accountNumber}}
                <div 
                  class="copy-btn"
                  @click="
                    copy(VAinfo.accountNumber);
                    makeToast(VAinfo.bank + ' ' + VAinfo.accountNumber);
                  "
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path d="M12.3291 6.00001V4.66668C12.3291 3.9303 11.7316 3.33334 10.9945 3.33334H4.32163C3.58457 3.33334 2.98706 3.9303 2.98706 4.66668V8.66668C2.98706 9.40306 3.58457 10 4.32163 10H5.6562M6.99077 12.6667H13.6636C14.4007 12.6667 14.9982 12.0697 14.9982 11.3333V7.33334C14.9982 6.59696 14.4007 6.00001 13.6636 6.00001H6.99077C6.25371 6.00001 5.6562 6.59696 5.6562 7.33334V11.3333C5.6562 12.0697 6.25371 12.6667 6.99077 12.6667ZM11.6618 9.33334C11.6618 10.0697 11.0643 10.6667 10.3272 10.6667C9.59014 10.6667 8.99263 10.0697 8.99263 9.33334C8.99263 8.59696 9.59014 8.00001 10.3272 8.00001C11.0643 8.00001 11.6618 8.59696 11.6618 9.33334Z" stroke="#FF5011" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  {{ $t("copy") }}
                </div>
              </div>
            </div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("instalment") }}</div>
              <div class="bill-label bill-value">{{ $t("instalmentOf", {current: currentPendingInstallment > 0 ? currentPendingInstallment : maxPendingInstallment, total: maxPendingInstallment}) }}</div>
              <!-- <div class="bill-label bill-value">{{ $t("instalmentOf", {current: paymentMethod == 'Credit Card' ? currentPendingCC : currentPendingInstallment, total: maxPendingInstallment}) }}</div> -->
            </div>
          </div>
        </div>
        <a href="tel:02150890822" class="bill-main-btn" v-if="paymentMethod == 'Credit Card'">{{ $t("btnCallCC") }}</a>
        <div class="bill-main-btn" @click="goRenewPolicy()" v-if="Global.validateRenewalPayment?.completed">{{ $t("renew-policy") }}</div>
        <div class="bill-main-btn" v-if="paymentMethod == 'Virtual Account'" @click="checkLastPayment()">{{ $t("btnMadePayment") }}</div>
        <div class="btn-row">
          <div @click="showModalVA = true;" class="bill-secondary-btn" v-if="paymentMethod !== 'Credit Card'">{{ $t("btnHowToPay") }}</div>
          <a class="bill-secondary-btn" href="https://myaccount.roojai.co.id" target="_blank">{{ $t("btnPayHistory") }}</a>
        </div>
      </div>
    </div>
  </div>
  <Modal 
    :show="showModalVA"
    :classModal="'garage'" 
    @close="showModalVA = false;" 
    :title="$t('modalVA.title')"
    width="328px"
    ref="closeModalVA"
  >
    <VirtualAccount 
      @close="this.$refs.closeModalVA.close()"
      :opportunity="opportunity"
      :amount="numberFormat(amount)"
      @changeBank="changeBank"
    />
  </Modal>
  <Modal 
    :show="showWaitingForPayment" 
    width="100%" 
    @close="showWaitingForPayment = false" 
    class="modal-call-phone"
    :title="$t('modalUnpaid.title')"
  >
    <div class="call-roojai">
      <div class="text-muted mt-3">
        {{ $t("modalUnpaid.p1") }}
      </div>
      <div class="text-muted">
        {{ $t("modalUnpaid.p2") }}
      </div>
      <div class="text-muted">
        {{ $t("modalUnpaid.opsHour1") }}
      </div>
      <div class="text-muted">
        {{ $t("modalUnpaid.opsHour2") }} <br>
        {{ $t("modalUnpaid.opsHour3") }} <br>
        {{ $t("modalUnpaid.opsHour4") }}
      </div>
      <div @click="showWaitingForPayment = false, showModalVA = true;" class="item-center">{{ $t("btnHowToPay") }}</div>
      <div class="unpaid-btn-container">
        <a href="tel:02150890822" class="bill-secondary-btn no-border w-50">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33234 3.33333C1.33234 2.23071 2.22685 1.33791 3.329 1.33791H5.51719C6.089 1.33791 6.59719 1.70349 6.77829 2.2463L7.77776 5.24193C7.98721 5.86969 7.70258 6.55534 7.11096 6.85088L6.20144 7.30522C6.7945 8.34173 7.65812 9.20442 8.69589 9.79679L9.15095 8.8875C9.447 8.29596 10.1329 8.01247 10.7601 8.22133L13.7585 9.21987C14.3011 9.40058 14.6677 9.90817 14.6677 10.4805V12.6667C14.6677 13.7693 13.7731 14.6621 12.671 14.6621H12.0037C6.11065 14.6621 1.33234 9.88909 1.33234 4V3.33333ZM3.329 2.66209C2.95702 2.66209 2.65652 2.96319 2.65652 3.33333V4C2.65652 9.1566 6.84082 13.3379 12.0037 13.3379H12.671C13.043 13.3379 13.3435 13.0368 13.3435 12.6667V10.4805C13.3435 10.4789 13.3424 10.477 13.3401 10.4762L10.3417 9.47768C10.3404 9.47725 10.3399 9.47728 10.3398 9.47729L10.3398 9.47729C10.3396 9.4773 10.3393 9.47733 10.339 9.47741C10.3386 9.47749 10.3382 9.47762 10.3377 9.47782C10.3372 9.47803 10.3368 9.47825 10.3365 9.47844C10.3362 9.47862 10.336 9.47877 10.3359 9.47886L10.3358 9.47905L10.3351 9.48014L9.582 10.985C9.42305 11.3026 9.04182 11.4382 8.718 11.2923C6.93954 10.4912 5.50733 9.06043 4.70535 7.28334C4.55911 6.95928 4.69491 6.57757 5.01296 6.41869L6.5192 5.66627L6.52041 5.66556L6.52063 5.66537C6.5208 5.66519 6.52119 5.66468 6.52154 5.66384C6.52189 5.663 6.52195 5.66242 6.52195 5.66227L6.52195 5.66216L6.52164 5.66102L5.52218 2.66539L6.13194 2.46195L5.52217 2.66539C5.52163 2.66375 5.51982 2.66209 5.51719 2.66209H3.329Z" fill="url(#paint0_linear_3449_1493)"/>
            <defs>
              <linearGradient id="paint0_linear_3449_1493" x1="8" y1="1.33791" x2="8" y2="14.6621" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF5011"/>
                <stop offset="1" stop-color="#FF6D39"/>
              </linearGradient>
            </defs>
          </svg>
{{ $t("modalUnpaid.contactUs") }}</a>
        <div @click="showWaitingForPayment = false" class="close-btn w-50" >{{ $t("modalUnpaid.close") }}</div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import axios from "axios";
import accounting from "accounting-js";
import dayjs from "dayjs";
import 'dayjs/locale/en'
import 'dayjs/locale/id'
import MasterCard from '../../public/images/mastercard.png'
import Visa from '../../public/images/visa.png'
import useClipboard from 'vue-clipboard3'
import Modal from '@/components/Modal'
import VirtualAccount from '@/components/VirtualAccount'

export default {
  name: "CheckLastPayment",
  mixins: [Mixin],
  components: {
    Modal,
    VirtualAccount,
  },
  setup() {
    const { toClipboard } = useClipboard()

    const copy = async (bank) => {
      try {
        await toClipboard(bank)
      } catch (e) {
        console.error(e)
      }
    }

    return { copy }
  },

  data() {
    return {
      Global,
      MasterCard : MasterCard,
      Visa : Visa,
      paymentMethod: '',
      months : [
        { id: "Januari", en: "Januari" },
        { id: "Februari", en: "February" },
        { id: "Maret", en: "March" },
        { id: "April", en: "April" },
        { id: "Mei", en: "May" },
        { id: "Juni", en: "June" },
        { id: "Juli", en: "July" },
        { id: "Agustus", en: "August" },
        { id: "September", en: "September" },
        { id: "Oktober", en: "October" },
        { id: "November", en: "November" },
        { id: "Desember", en: "December" },
      ],
      opportunity : "",
      productType : "",
      name : "",
      contractStart : "2024-05-05T17:00:00Z",
      contractEnd : "2025-05-04T17:00:00Z",
      currentPendingInstallment : 11,
      currentDueDate : "2024-09-05T17:00:00Z",
      maxPendingInstallment : 12,
      amount : 0,
      paymentMethod : "",
      cardNumber : "",
      vaNumber : "",
      cardIssueBank : "",
      completed : false,
      showModalVA: false,
      ...Global.validateRenewalPayment,
      VAinfo: {
        bank: "",
        accountNumber: '',
      },
      currentDueMonth: '',
      nextDueMonth: null,
      showWaitingForPayment: false, 
    };
  },
  async created() {
    
  },
  async mounted() {
    let vm = this;
    vm.loading("hide");
    await vm.validateRenewalPayment(Global.quote.opportunityNumber);
    let getCurrentDueMonth = (new Date(Global.validateRenewalPayment.currentDueDate)).getMonth()
    this.currentDueMonth = this.months[getCurrentDueMonth]
    this.nextDueMonth = this.currentPendingInstallment < 12 ? this.months[getCurrentDueMonth+1] : null
  },
  methods: {
    numberFormat(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changeBank(v) {
      this.VAinfo = v;
    },
    makeToast(message) {
      var vm = this;
      vm.$toast.open({
        message: message,
        type: "info",
        duration: 5000,
        position: Global.platformId === 'ios' ? "bottom" : "top-right",
        dismissible: true
      })
    },
    async checkLastPayment(){
      let vm = this;
      Global.currentPendingInstallment = Global.validateRenewalPayment.currentPendingInstallment;
      let check = await vm.validateRenewalPayment(Global.quote.opportunityNumber);
      // this.$router.push({name:'payment-info-success'})
      if(check && check.completed){
        this.$router.push({name:'payment-info-success'})
      } else if(check && Number(check.currentPendingInstallment) > Number(Global.currentPendingInstallment)){
        this.$router.push({name:'payment-info-success'})
      } else {
        this.showWaitingForPayment = true;
      }
    },
    goRenewPolicy(){
      Global.isRetrieveToPayment = true;
      // Global.paymentFrequency= 'Yearly';
      this.$router.push({ path: 'payment', query: { tp: 'ren' }})
    }
  },
  watch: {
    
  },
  computed: {
    contractEndFormat: function(v){
      dayjs.locale(Global.quote.prefLang)
      return dayjs(this.contractEnd).format("DD MMM YYYY")
    },
    currentPendingCC: function(){
      let pending = Global.validateRenewalPayment.currentPendingInstallment
      let idText = pending == 12 ? pending : '11 dan 12 ';
      let endText = pending == 12 ? pending : '11th & 12th ';
      return Global.quote.prefLang == 'id' ? idText : endText
    }
  },
};
</script>

<i18n>
  {
    "en": {
      "unpaidTitle" : "Please complete your remaining insurance bill",
      "unpaidDesc" : "Before you can renew your insurance policy, please complete your remaining insurance bill first.",
      "unpaidDescCC" : "Before you can renew your insurance policy, please complete your remaining insurance bill first by contacting our Contact Center.",
      "billInfo" : "Bill Information",
      "currency" : "IDR ",
      "totalAmount" : "Total Amount Due",
      "policyNumber" : "Policy Number",
      "insuranceProduct" : "Insurance Product",
      "insuredName" : "Insured Name",
      "policyExpireDate" : "Policy Expiry Date",
      "bankName" : "Bank Name",
      "ccNumber" : "Credit Card Number",
      "vaNumber" : "Virtual Account Number",
      "instalment" : "Instalment",
      "instalmentOf" : "{current} out of {total}",
      "btnMadePayment" : "I've Made the Payment",
      "btnCallCC" : "Contact Us",
      "btnHowToPay" : "See How to Pay",
      "btnPayHistory" : "See Payment History",
      "copied" : "Copied",
      "copy" : "Copy",
      "modalVA": {
        "title" : "How to Pay",
        "chooseBank" : "Choose Bank",
        "amount" : "Price to be paid",
        "closeBtn" : "Close",
      },
      modalUnpaid: {
        "title" : "We haven’t received your payment",
        "p1" : "Please complete the payment of your remaining instalment first.",
        "p2" : "Having problems making payment? Please contact Roojai Contact Center.",
        "opsHour1": "Roojai’s operational hours:",
        "opsHour2": "Monday - Friday: 08:00 – 19:00",
        "opsHour3": "Saturday: 08:00 – 17:00",
        "opsHour4": "Public Holiday: Off",
        "contactUs" : "Contact Us",
        "close" : "Close",
      },
      "billMonth": "{month}'s Instalment",
      "billRenewal": "Policy Renewal",
      "renew-policy": "I'm Ready to Renew My Policy",
    },
    "id": {
      "unpaidTitle" : "Mohon selesaikan pembayaran sisa cicilan asuransi",
      "unpaidDesc" : "Untuk memperpanjang polis asuransi, mohon selesaikan pembayaran sisa cicilan asuransi kamu terlebih dahulu.",
      "unpaidDescCC" : "Untuk memperpanjang polis asuransi, mohon selesaikan pembayaran sisa cicilan asuransi kamu terlebih dahulu dengan menghubungi Contact Center kami.",
      "billInfo" : "Informasi Tagihan",
      "currency" : "Rp",
      "totalAmount" : "Total Tagihan",
      "policyNumber" : "Nomor Polis",
      "insuranceProduct" : "Produk Asuransi",
      "insuredName" : "Nama Tertanggung",
      "policyExpireDate" : "Tanggal Berakhir Polis",
      "bankName" : "Nama Bank",
      "ccNumber" : "Nomor Kartu Kredit",
      "vaNumber" : "Nomor Virtual Account",
      "instalment" : "Cicilan ke",
      "instalmentOf" : "{current} dari {total}",
      "btnMadePayment" : "Saya Sudah Membayar",
      "btnCallCC" : "Hubungi Contact Center",
      "btnHowToPay" : "Lihat Cara Bayar",
      "btnPayHistory" : "Lihat Riwayat Pembayaran",
      "copied" : "Tersalin",
      "copy" : "Salin",
      "modalVA": {
        "title" : "Cara Pembayaran",
        "chooseBank" : "Pilih Bank",
        "amount" : "Tagihan yang harus dibayar",
        "closeBtn" : "Tutup",
      },
      modalUnpaid: {
        "title" : "Pembayaranmu belum kami terima",
        "p1" : "Mohon lakukan pembayaran tagihan terlebih dahulu.",
        "p2" : "Mengalami kendala saat pembayaran tagihan? Silakan hubungi Contact Center Roojai.",
        "opsHour1": "Waktu operasional Roojai:",
        "opsHour2": "Senin - Jumat: 08:00 – 19:00",
        "opsHour3": "Sabtu: 08:00 – 17:00",
        "opsHour4": "Hari Libur Nasional: Off",
        "contactUs" : "Hubungi Kami",
        "close" : "Tutup",
      },
      "billMonth": "Tagihan {month}",
      "billRenewal": "Perpanjang Polis",
      "renew-policy": "Lanjut Perpanjang Polis Saya",
    },
  }
</i18n>
