<style>
  .topper-area {
    padding: 4px auto;
    gap: 8px;
    color: #C5D9EB;
    font-family: Public Sans;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    background: #2A5C88;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
  }
  .topper-area::-webkit-scrollbar {
    display: none;
  }
  .topper-area .part{
    display: flex;
    max-width: 130px;
    gap: 5px;
    padding: 4px;
  }
  .unselected {
    color: #557EA3;
  }
  .icon-number {
    width: 18px;
    height: 18px;
    border-radius: 10px;
    background: #557EA3;
    color: #9FB9D1;
    line-height: 18px;
    font-size: 12px;
    font-family: Noto Sans;
    overflow: hidden;
    text-align: center;
  }
  .spacer {
    width: 24px;
    height: 0;
    border: 1px solid #557EA3;
    border-radius: 5px;
  }
  .top-area {
    background: linear-gradient(180deg, #2A5C88 0%, #003B70 100%);
    border-radius: 0 0 0 84px;
    padding: 16px 24px;
    gap: 8px;
    font-family: Public Sans;
    font-size: 54px;
    line-height: 60px;
    font-weight: 700;
    color: #fff;
    text-align: center;
  }
  .content-area {
    display: flex;
    margin: 16px;
    align-items: center;
  }
  .content-box {
    background: #EBEBEB;
    border: 2px solid #f5f5f5;
    border-radius: 16px 32px;
    gap: 12px;
    padding: 16px;
    width: 328px;
    margin: auto;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    font-family: Noto Sans;
    text-align: center;
    color: #393939;
    display: flex;
    flex-direction: column;
  }
  .bill-container {
    background: #fff;
    padding: 0 0 16px 0;
    gap: 12px;
    border-radius: 8px 24px;
    overflow: hidden;
  }
  .bill-header {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    font-family: Public Sans;
    line-height: 22px;
    padding: 16px 48px;
    gap: 8px;
    display: flex;
    background: linear-gradient(180deg, #2A5C88 0%, #003B70 100%);
  }
  .bill-content {
    padding: 16px 16px 0 16px;
    gap: 8px;
    display: flex;
    flex-direction: column;
  }
  .bill-row{
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #CCC;
    gap: 5px;
  }
  .bill-amount {
    font-family: Public Sans;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    color: #003B70;
  }
  .bill-label {
    font-family: Public Sans;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #6F6F6F;
    text-align: left;
  }
  .bill-value {
    font-weight: 700;
    font-size: 10px;
    color: #161616;
    text-align: right;
  }
  .copy-btn{
    display: flex;
    color: #FF5011;
    font-size: 12px;
    font-family: Public Sans;
    line-height: 16px;
    font-weight: 700;
    float: right;
    gap: 4px;
    border-bottom: 1px dashed #FF5011;
    cursor: pointer;
  }
  .text-center{
    text-align: center ;
  }
  .bill-main-btn {
    background: linear-gradient(180deg, #FF6D39 0%, #FF5011 100%);
    display: flex;
    padding: 12px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-family: Public Sans;
    line-height: 22px;
    font-weight: 700;
    justify-content: center;
    cursor: pointer;
  }
  .bill-main-btn:hover {
    background: linear-gradient(180deg, #FF5011 0%, #D4430E 100%);
  }
  .bill-main-btn:active {
    background: linear-gradient(180deg, #D4430E 0%, #AA350B 100%);
  }
  .bill-secondary-btn {
    background: #FFECE5;
    display: flex;
    padding: 6px 8px;
    border-radius: 8px;
    color: #FF5011 !important;
    font-size: 12px;
    font-family: Public Sans;
    line-height: 16px;
    font-weight: 700;
    justify-content: center;
    border: .5px solid #FF5011;
    cursor: pointer;
    flex-grow: 1;
  }
  .bill-secondary-btn:hover {
    background: #FFD2C2;
    text-decoration: none;
  }
  .bill-secondary-btn:active {
    background: #FFBBA3;
  }
  .btn-row {
    display: flex;
    gap: 8px; 
  }
  @media (max-width: 640px) {
    .top-area{
      font-size: 24px;
      line-height: 28px;
      text-align: left;
      border-radius: 0 0 0 32px;
    }
  }
  .call-roojai {
    padding: 0px 16px 16px 16px;
  }

  .call-roojai .text-muted {
      display: flex;
      padding-bottom: 16px;
      max-width: 245px;
      color: #393939;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
  }

  .call-roojai .text-muted .glyphicons {
      margin-right: 5px;
  }
  .item-center{
    color: #FF5011;
    font-size: 12px;
    font-family: Public Sans;
    line-height: 16px;
    font-weight: 700;
    gap: 4px;
    border-bottom: 1px dashed #FF5011;
    cursor: pointer;
    text-align: center;
    width: 100px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  .no-border{
    border: 0 !important;
  }
  .unpaid-btn-container{
    display: flex;
    gap: 8px;
  }
  .close-btn{
    display: flex;
    padding: 6px 8px;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 8px;
    background: linear-gradient(180deg, #FF6D39 0%, #FF5011 100%);
    color: #FFF;
    text-align: center;
    font-family: "Public Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.24px;
    cursor: pointer;
  }
  .close-btn:hover {
      background: linear-gradient(180deg, #FF5011 0%, #D4430E 100%);
  }
  .close-btn:active {
      background: linear-gradient(180deg, #D4430E 0%, #AA350B 100%);
  }
  .bg-green{
    background: #42BE65;
  }
  .bg-green svg{
    margin: auto;
  }
</style>

<template>
  <div class="">
    <div class="topper-area">
      <div class="part">
        <div class="icon-number bg-green">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 6.5L4.5 8.5L9.5 3.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
      </div>
        <div class="flex-grow-1">{{ $t("billMonth", {month: Global.validateRenewalPayment?.completed ? currentDueMonth.id : prevMonth.id}) }}</div>
      </div>
      <!-- <div class="spacer" v-if="!Global.validateRenewalPayment?.completed"></div>
      <div class="part" v-if="!Global.validateRenewalPayment?.completed">
        <div class="icon-number">2</div>
        <div class="flex-grow-1 unselected">{{ $t("billMonth", {month: currentDueMonth.id}) }}</div>
      </div> -->
      <div class="spacer"></div>
      <div class="part">
        <div class="icon-number">2</div>
        <div class="flex-grow-1 unselected">{{ $t("billRenewal") }}</div>  
      </div>
    </div>
    <div class="top-area">{{ $t("paidTitle") }}</div>
    <div class="content-area">
      <div class="content-box">
        <div>{{ $t("paidDesc",{product_name: productType, month: Global.quote.prefLang == 'id' ? prevMonth.id : prevMonth.en}) }}</div>
        <div class="bill-container">
          <div class="bill-header">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.50008 4.16667H5.83341C4.91294 4.16667 4.16675 4.91286 4.16675 5.83333V15.8333C4.16675 16.7538 4.91294 17.5 5.83341 17.5H14.1667C15.0872 17.5 15.8334 16.7538 15.8334 15.8333V5.83333C15.8334 4.91286 15.0872 4.16667 14.1667 4.16667H12.5001M7.50008 4.16667C7.50008 5.08714 8.24627 5.83333 9.16675 5.83333H10.8334C11.7539 5.83333 12.5001 5.08714 12.5001 4.16667M7.50008 4.16667C7.50008 3.24619 8.24627 2.5 9.16675 2.5H10.8334C11.7539 2.5 12.5001 3.24619 12.5001 4.16667M10.0001 10H12.5001M10.0001 13.3333H12.5001M7.50008 10H7.50841M7.50008 13.3333H7.50841" stroke="white" stroke-width="1.4" stroke-linecap="round"/>
            </svg>
            {{ $t("billInfo") }}
          </div>
          <div class="bill-content">
            <div class="bill-label text-center">{{ $t("totalAmount") }}</div>
            <div class="bill-amount">{{ $t("currency") }}{{numberFormat(parseInt(amount))}},-</div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("policyNumber") }}</div>
              <div class="bill-label bill-value">{{Global.validateRenewalPayment?.opportunity}}</div>
            </div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("payment-method") }}</div>
              <div class="bill-label bill-value">{{Global.validateRenewalPayment?.paymentMethod}}</div>
            </div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("pay-date") }}</div>
              <div class="bill-label bill-value">{{lastPaid}}</div>
            </div>
            <div class="bill-row">
              <div class="bill-label">{{ $t("instalment") }}</div>
              <div class="bill-label bill-value">{{ $t("instalmentOf", {current: currentPendingInstallment > 0 ? (currentPendingInstallment-1) : maxPendingInstallment, total: maxPendingInstallment}) }}</div>
            </div>
          </div>
        </div>
        <div class="bill-main-btn" @click="goRenewPolicy()" v-if="Global.validateRenewalPayment?.completed">{{ $t("renew-policy") }}</div>
        <div class="bill-main-btn" @click="back()" v-else>{{ $t("pay-next-bill") }}</div>
        
      </div>
    </div>
  </div>
  
</template>

<script>
import Mixin from "@/components/Mixin";
import Global from "@/store/global.js";
import axios from "axios";
import accounting from "accounting-js";
import dayjs from "@/dayjs";
import MasterCard from '../../public/images/mastercard.png'
import Visa from '../../public/images/visa.png'
import useClipboard from 'vue-clipboard3'
import Modal from '@/components/Modal'
import VirtualAccount from '@/components/VirtualAccount'

export default {
  name: "CheckLastPayment",
  mixins: [Mixin],
  components: {
    Modal,
    VirtualAccount,
  },
  setup() {
    const { toClipboard } = useClipboard()

    const copy = async (bank) => {
      try {
        await toClipboard(bank)
      } catch (e) {
        console.error(e)
      }
    }

    return { copy }
  },

  data() {
    return {
      Global,
      MasterCard : MasterCard,
      Visa : Visa,
      paymentMethod: '',
      months : [
        { id: "Januari", en: "Januari" },
        { id: "Februari", en: "February" },
        { id: "Maret", en: "March" },
        { id: "April", en: "April" },
        { id: "Mei", en: "May" },
        { id: "Juni", en: "June" },
        { id: "Juli", en: "July" },
        { id: "Agustus", en: "August" },
        { id: "September", en: "September" },
        { id: "Oktober", en: "October" },
        { id: "November", en: "November" },
        { id: "Desember", en: "December" },
      ],
      opportunity : "",
      productType : "",
      name : "",
      contractStart : "2024-05-05T17:00:00Z",
      contractEnd : "2025-05-04T17:00:00Z",
      currentPendingInstallment : 0,
      currentDueDate : "2024-09-05T17:00:00Z",
      lastPaidDate : "2024-09-05T17:00:00Z",
      maxPendingInstallment : 12,
      amount : 0,
      paymentMethod : "",
      cardNumber : "",
      vaNumber : "",
      cardIssueBank : "",
      completed : false,
      showModalVA: false,
      ...Global.validateRenewalPayment,
      VAinfo: {
        bank: "",
        accountNumber: '',
      },
      currentDueMonth: '',
      nextDueMonth: null,
      prevMonth: '',
      showWaitingForPayment: false, 
    };
  },
  async created() {
    
  },
  async mounted() {
    let vm = this;
    vm.loading("hide");
    await vm.validateRenewalPayment(Global.quote.opportunityNumber);
    let getCurrentDueMonth = (new Date(this.currentDueDate)).getMonth()
    this.currentDueMonth = this.months[getCurrentDueMonth]
    this.prevMonth = this.months[getCurrentDueMonth-1] 
  },
  methods: {
    numberFormat(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    back(){
      this.$router.go(-1)
    },
    goRenewPolicy(){
      Global.isRetrieveToPayment = true;
      this.$router.push({ path: 'payment', query: { tp: 'ren' }})
    }
  },
  watch: {
    
  },
  computed: {
    lastPaid: function(v){
      dayjs.locale(Global.quote.prefLang)
      return dayjs(this.lastPaidDate).format("DD MMM YYYY")
    }
  },
};
</script>

<i18n>
  {
    "en": {
      "paidTitle" : "Payment Success!",
      "paidDesc" : "Thank you! Your insurance instalment payment for {month} has been received.",
      "billInfo" : "Payment Details",
      "currency" : "IDR ",
      "totalAmount" : "Total Paid",
      "policyNumber" : "Policy Number",
      "insuranceProduct" : "Insurance Product",
      "insuredName" : "Insured Name",
      "policyExpireDate" : "Policy Expiry Date",
      "payment-method": "Payment Method",
      "pay-date": "Payment Date",
      "instalment" : "Instalment",
      "instalmentOf" : "{current} out of {total}",
      "billMonth": "{month}'s Instalment",
      "billRenewal": "Policy Renewal",
      "pay-next-bill": "Pay the Next Bill",
      "renew-policy": "I'm Ready to Renew My Policy",
    },
    "id": {
      "paidTitle" : "Pembayaran Berhasil!",
      "paidDesc" : "Terima kasih! Pembayaran cicilan asuransi {product_name} bulan {month} telah diterima.",
      "billInfo" : "Riwayat Pembayaran",
      "currency" : "Rp",
      "totalAmount" : "Total Bayar",
      "policyNumber" : "Nomor Polis",
      "insuranceProduct" : "Produk Asuransi",
      "insuredName" : "Nama Tertanggung",
      "policyExpireDate" : "Tanggal Berakhir Polis",
      "payment-method": "Metode Pembayaran",
      "pay-date": "Tanggal Pembayaran",
      "instalment" : "Cicilan ke",
      "instalmentOf" : "{current} dari {total}",
      "billMonth": "Tagihan {month}",
      "billRenewal": "Perpanjang Polis",
      "pay-next-bill": "Bayar Tagihan Berikutnya",
      "renew-policy": "Lanjut Perpanjang Polis Saya",
    },
  }
</i18n>
