<template>
<div class="modal modal-mv" v-if="show">
  <div :id="'modalGlobal'+id" class="modal-dialog modal-dialog-scrollable slideIn" :style="'width:' + width +';'" :class="classModal">
    <div class="modal-content" :class="{voucher: voucher, }" :style="tooltips ? {'background': 'rgba(0, 0, 0, 0.7)'} : classModal == 'garage' ? {'background': '#EBEBEB'} : {}">
        <div class="modal-title" v-if="title !== ''">
            <div class="d-flex" style="justify-content: space-between;">
                <h1 class="title-modal">{{this.title}}</h1>
                <button class="btn btn-close-modal" :class="{voucher: voucher}" @click="close()" v-if="this.title != '' && !voucherClose">
                    <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3 9L9 3M3 3L9 9"
                            stroke="#A8A8A8"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div> 
      <slot />
    </div>
  </div>
</div>
</template>

<script>

export default {
    name: "Modal",
    data(){
        return{
            id: '',
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        voucher: {
            type: Boolean,
            default: false
        },
        voucherClose: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "500px"
        },
        title: {
            type: String,
            default: ""
        },
        classModal: {
            type: String,
            default: ""
        },
        tooltips: {
            type: String,
            default: null
        },
    },
    methods: {
        close(){
            var changeClass = $('#modalGlobal'+this.id)
            changeClass.removeClass('slideIn')
            changeClass.addClass('slideOut')
            setTimeout(() => {
                this.$emit("close");
            }, 500);
        }
    },
    created(){
        let _id = new Date()
        this.id = _id.getTime().toString()
    }
}
</script>
<style scoped>
.modal {
    display: block;
}
.modal-dialog-scrollable {
  display: -ms-flexbox;
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-content {
  overflow-y: auto;
}
.modal-mv {
    text-align: center;
    padding: 0!important;
    background-color: rgba(0,0,0,0.7)!important;
}
.modal-mv .modal-dialog {
    /* margin: 5rem auto!important; */
    top: 20%;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    max-width: 328px !important;
}
.modal-mv .modal-dialog.garage{
    top: 4% !important;
}
.modal-mv .modal-dialog.pametpl{
    top: 4% !important;
}
.modal-mv .modal-dialog.voucher{
    top: 1% !important;
}
.modal-mv .modal-content.voucher {
    border-radius: 8px!important;
    background-image: linear-gradient(180deg, #00539A 0%, #003A6D 100%);
    background-position: top center;
    background-repeat: no-repeat;
    position: static;
}

.modal-mv .modal-content {
    border-radius: 8px!important;
}
.modal-mv .modal-content::-webkit-scrollbar {
  display: none;
}

.modal-mv .modal-content .modal-title{
    margin:16px 16px 0px 16px;
}
.modal-mv .modal-content.voucher .modal-title .title-modal{
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
    text-align: center;
    width: 100%;
    max-width: none !important;
}
.modal-mv .modal-content .modal-title .title-modal{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #003B70;
    max-width: 228px;
    margin-bottom: 0px !important;
}

.modal-mv .modal-content .modal-title .btn-close-modal{
    display: flex;
    padding: 6px;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #EBEBEB;
    background: #F4F9FF;
    right: 0;
    width: 24px;
    height: 24px;
}

.modal-mv .modal-content .modal-title .btn-close-modal.voucher{
    opacity: 1;
    position: absolute;
    top: -10px;
    z-index: 1760;
    right: 2px;
}

.modal-mv .modal-content .btn-close-modal:hover{
    background-color: #D6E6FF;
}  
.slideIn {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slideOut {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slideRight {
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.slideLeft {
    -webkit-animation-name: slideInLeft;
    animation-name: slideInLeft;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideInUp {
    0% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        visibility: visible;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}
@keyframes slideInDown {
    from {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        visibility: visible;
    }
    to {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
}


@-webkit-keyframes slideInRight {
    from { 
        -webkit-transform: translateX(100%); 
        transform: translateX(100%);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}
@keyframes slideInRight {
    from { 
        -webkit-transform: translateX(100%); 
        transform: translateX(100%);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }
}

@-webkit-keyframes slideInLeft {
    from { 
        -webkit-transform: translateX(0); 
        transform: translateX(0);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}
@keyframes slideInLeft {
    from { 
        -webkit-transform: translateX(0); 
        transform: translateX(0);
        visibility: visible;
    }

    to {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}

@-webkit-keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}
@keyframes shake {
    0%, 100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
} 

@media screen and (max-width: 480px) {
    .modal-dialog {
        max-width: 93%;
    }
}
</style>